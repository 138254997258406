import React, { useEffect, useState } from 'react';
import "../homecarousel/homecarousel.css";
import "../homecarousel/homecarouselresponsive.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Card } from 'react-bootstrap';
import slide1 from "../homecarousel/slide1.jpg";
import slide2 from "../homecarousel/slide2.jpg";
import slide3 from "../homecarousel/slide3.jpg";
import { useDispatch, useSelector } from 'react-redux';
import { saveDataToAPI } from '../../../services/actions/apiActions';
import axios from 'axios';
import DOMPurify from 'dompurify';

const HomeCarousel = () => {
  const [data, setData] = useState();
  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axios.post(`${process.env.REACT_APP_API_SERVER}/getcollaborations`, { token: process.env.REACT_APP_API_TOKEN });
        if (data.status === 200) {
          setData(data.content)
        }
      } catch (err) {
        console.log(err)
      }

    }
    fetchData();
  }, []);
  //    Start Scroll To Top Start Page Code 
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0 });
    });
  }, []);
  //    End Scroll To Top Start Page Code 
  // console.log(data?.length)
  // Start Home Owl Carousel Responsive Code
  const homeslider = {
    loop: true,
    margin: 0,
    // nav: true,

    // smartSpeed:3000,
    // autoplaySpeed: 6000,
    // autoplayspeed: 2500,
    // autoplaytimeout: 1500,
    // slidetransition: "linear",
    autoplay: false,
    // autoplayTimeout: 6000,
    // autoplayHoverPause: true,

    responsive: {
      0: {
        items: 1,
        // stagePadding: 10,
        // autoplay: false,

      },
      768: {
        items: 2,
        // stagePadding: 100,

      },
      992: {
        items: 3,
        stagePadding: 0,

      },


    },
  };
  // End Woods Owl Carousel Responsive Code
  return (
    <>
      {/* Start Home Page Carousel We Believe Collaborations create scope of technical interventions Portion Code Here   */}
      {data &&
        <div className='home_carousel'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='carousel_title'>
                  <h5>Partnerships:</h5>
                  <h2>Unlocking Success Through Collaboration</h2>
                </div>
              </div>
              <div className='col-12'>
                <OwlCarousel className="owl-theme" {...homeslider} autoplaySpeed={2500}
                  autoplayTimeout={3000}

                // slidetransition={linear}
                >
                  {data?.map((data) => (
                    <div className="item">
                      <Card>
                        <Card.Img variant="top" src={data.strImage} />
                        <Card.Body>
                          <Card.Title>{data.strTitle}</Card.Title>
                          {/* <Card.Text dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              data?.strDescription
                            ),
                          }}>

                          </Card.Text> */}
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div >
        </div >
      }
      {/* End Home Page Carousel We Believe Collaborations create scope of technical interventions Portion Code Here   */}

    </>
  )
}

export default HomeCarousel
