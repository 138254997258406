import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

function PostLike({ data, type }) {
    // console.log(data)
    const location = useLocation();

    useEffect(() => { }, [location]);
    const localLikeData = JSON.parse(localStorage.getItem("blogLikeData"));

    // Start Like and Unliked Portion Code Here



    const [toggleLike, setToggleLike] = useState(localLikeData && localLikeData?.type == type && localLikeData?.isLiked === true && localLikeData?.id == data?.id ? "Like" : "UnLike");
    const [blogLikeCount, setBlogLikeCount] = useState(Number(data?.like));
    // console.log(toggleLike);
    const toggleLikes = async (id, like) => {

        if (toggleLike === "UnLike") {
            setToggleLike("Like");

            const addLikeData = {
                id: id,
                token: process.env.REACT_APP_API_TOKEN,
                likedCount: Number(localLikeData && localLikeData?.id == data.id ? localLikeData.count + 1 : data.like + 1)
            }
            const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/updatepostlike`, addLikeData)
            setBlogLikeCount(res.data.likedCount);

            const localData = {
                id: data.id,
                type: type,
                isLiked: true,
                count: res.data.likedCount
            }
            localStorage.setItem("blogLikeData", JSON.stringify(localData));

        }
        if (toggleLike === "Like") {

            setToggleLike("UnLike");
            const romoveLikeData = {
                id: id,
                token: process.env.REACT_APP_API_TOKEN,
                likedCount: Number(localLikeData && localLikeData?.id == data.id ? localLikeData.count - 1 : data.like - 1)
            }
            const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/updatepostlike`, romoveLikeData)
            setBlogLikeCount(res.data.likedCount);
            const localData = {
                id: data.id,
                type: type,
                isLiked: false,
                count: res.data.likedCount
            }
            localStorage.setItem("blogLikeData", JSON.stringify(localData));



        }
    };

    // End Like and Unliked Portion Code Here
    return (
        <>
            <div className='like_box' >
                {type == "paper" &&
                    <Link className='download_blog' to={data ? data.strFileAttach : ""} target='_blank'>
                        <svg id="Component_47_3" data-name="Component 47 – 3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.008 36.646">
                            <g id="Group_180" data-name="Group 180" transform="translate(1587.476 -1627.246) rotate(90)">
                                <path id="Path_73" data-name="Path 73" d="M-3576.721-9233.079h31.363" transform="translate(5203.966 10804.186)" fill="none" stroke="#fff" strokeWidth="2" />
                                <path id="Path_74" data-name="Path 74" d="M-3495.606-9264.768l9.869,9.869-9.869,9.869" transform="translate(5144.346 10826.005)" fill="none" stroke="#fff" strokeWidth="2" />
                            </g>
                            <path id="Path_175" data-name="Path 175" d="M-3554.186-5841.782h-33.008" transform="translate(3587.194 5877.928)" fill="none" stroke="#fff" strokeWidth="2" />
                        </svg></Link>
                }
                <Button onClick={() => toggleLikes(data.id, data.like)}>
                    {toggleLike === "UnLike" ? (
                        <i className="far fa-thumbs-up"></i>
                    ) : (

                        <i className="fas fa-thumbs-up"></i>
                    )}
                </Button>

                <small>{blogLikeCount ? blogLikeCount : data?.like}</small>
                {type != "paper" &&
                    <p>{localLikeData != null ? localLikeData?.type == type || localLikeData?.id == data?.id ? "Liked" : "Like This Blog" : "Like This Blog"} </p>
                }
            </div>
        </>
    )
}

export default PostLike