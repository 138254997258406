import axios from "axios";
import { FETCH_DATA2_FAILURE, FETCH_DATA2_REQUEST, FETCH_DATA2_SUCCESS, SAVE_DATA_FAILURE, SAVE_DATA_REQUEST, SAVE_DATA_SUCCESS } from "../constant";
import ApiService from "../ApiService";

export const fetchPostCategoryDataRequest = () => ({
    type: FETCH_DATA2_REQUEST,
});

export const fetchPostCategoryDataSuccess = (data) => ({
    type: FETCH_DATA2_SUCCESS,
    payload: data,
});

export const fetchPostCategoryDataFailure = (error) => ({
    type: FETCH_DATA2_FAILURE,
    payload: error,
});


// Action creators
export const saveDataRequest = () => ({
    type: SAVE_DATA_REQUEST,
});

export const saveDataSuccess = (data) => ({
    type: SAVE_DATA_SUCCESS,
    payload: data,
});

export const saveDataFailure = (error) => ({
    type: SAVE_DATA_FAILURE,
    payload: error,
});


export const fetchPostCategoryData = () => {

    return async (dispatch) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_SERVER}/getCategorydata`, { token: process.env.REACT_APP_API_TOKEN });
            dispatch(fetchPostCategoryDataSuccess(response.data));
        } catch (error) {
            dispatch(fetchPostCategoryDataFailure(error));
        }
    };

};




export const saveDataToAPI = (endpoint, data) => async (dispatch) => {
    // dispatch(saveDataRequest());
    const apiData = {
        ...data, token: process.env.REACT_APP_API_TOKEN
    }
    try {
        const response = await ApiService.saveData(endpoint, apiData);
        dispatch(saveDataSuccess(response.data));
    } catch (error) {
        dispatch(saveDataFailure(error));
    }
};







