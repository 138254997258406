import React, { useEffect } from "react";
import "../TermsCondition/TermsCondition.css";
import { useDispatch, useSelector } from "react-redux";
import { saveDataToAPI } from "../../services/actions/apiActions";
import Error from "../../ErrorPage/Error";
import DOMPurify from "dompurify";


const TermsCondition = () => {

  const dispatch = useDispatch();
  const error = useSelector((state) => state.apiData.error);
  const data = useSelector((state) => state.apiData.data.content);
  useEffect(() => {
    dispatch(saveDataToAPI("termsconditiondata"));
  }, [dispatch]);


  //    Start Scroll To Top Start Page Code 
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0 });
    });
  }, []);
  //    End Scroll To Top Start Page Code 


  return (
    error ? <Error msg={error.message} /> :
    <>
      {/* Start TermsCondition code here */}

      <div className="terms_content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p className="terms_heading">TERMS & CONDITIONS</p>
              <div className="col-12 increment">
                <div dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    data?.strTitle
                  ),
                }}>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      {/* End TermsCondition code here */}
    </>
  );
};

export default TermsCondition;
