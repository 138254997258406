import React, { useRef, useState } from 'react'
import { Button, Card, Form, Modal } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2'
import { Controller, set, useForm } from 'react-hook-form';
import swal from 'sweetalert';
import axios from 'axios';
import Loader from '../Loader/Loader';
import DOMPurify from 'dompurify';

function JoinUsForm({ show, setShow, type, jobData }) {
    const phoneRef = useRef();
    const [selectedFile, setSelectedFile] = useState(null);

    const [loader, setLoader] = useState(false);

    const handleFileSelect = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    };


    const { register, handleSubmit, control, reset, formState: { errors }, setValue } = useForm();

    const hideModal = () => {
        setShow(false);
        reset();
        setSelectedFile(null);
    }

    const onSubmit = async (data) => {
        try {
            setLoader(true);
            data.resume = data.resume[0];
            if (type !== "uploadCv") {
                data.position = jobData.title;
            }
            const apiData = {
                ...data, token: process.env.REACT_APP_API_TOKEN
            }
            const response = await axios.post(`${process.env.REACT_APP_API_SERVER}/saveJobApplication`, apiData, { headers: { "Content-Type": "multipart/form-data" } })
            if (response.data.status == 200) {
                reset();
                setShow(false);
                swal(
                    `You have applied for ${data.position}`,
                    "Nascent Team will connect you soon",
                    "success"
                );
                setLoader(false);
                setSelectedFile(null);
            }
        } catch (error) {
            swal(
                "Server Error",
                error.message,
                "error"
            );
            setLoader(false);
        }

    };


    return (
        <>
            {type == "uploadCv" ?

                <Modal show={show} onHide={hideModal} centered>
                    <Modal.Header>
                        <Modal.Title>Upload Resume</Modal.Title>
                        <Button aria-hidden="true" onClick={hideModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 24 24">
                                <g id="Group_3024" data-name="Group 3024" transform="translate(-1727 -51)">
                                    <g id="Group_3023" data-name="Group 3023" transform="translate(-0.097 -0.139)">
                                        <path id="Path_21074" data-name="Path 21074" d="M-3824.9,5085.139l15.754,15.754" transform="translate(5556 -5030)" fill="none" stroke="#fff" strokeWidth="2" />
                                        <path id="Path_21075" data-name="Path 21075" d="M-3824.9,5085.139l15.754,15.754" transform="translate(6831.99 3880.042) rotate(90)" fill="none" stroke="#fff" strokeWidth="2" />
                                    </g>
                                    <rect id="Rectangle_402" data-name="Rectangle 402" width="24" height="24" transform="translate(1727 51)" fill="none" />
                                </g>
                            </svg>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className='collaborate_form' onSubmit={handleSubmit(onSubmit)}>
                            <div className='row'>

                                <div className='col-lg-12 form-group'>
                                    <Form.Label>Full Name<span>*</span></Form.Label>

                                    <Form.Control type='text' name='fullName'
                                        {...register("fullName", {
                                            required: "Please fill out this field.",
                                            pattern: {
                                                value: /^[a-zA-Z ]*$/,
                                                message: "Only alphabetical is allowed in name field."
                                            },
                                            minLength:
                                            {
                                                value: '3',
                                                message: 'Minimum 3 characters are allowed.'
                                            },
                                            maxLength:
                                            {
                                                value: '50',
                                                message: 'Maximum 50 characters are allowed.'
                                            }
                                        })} />
                                    {errors.fullName && <label className="error">
                                        {errors.fullName.message}
                                    </label>} 
                                </div>
                                <div className='col-lg-12  form-group'>
                                    <Form.Label>Email<span>*</span></Form.Label>
                                    <Form.Control type='text' name='email'
                                        {...register("email", {
                                            required: "Please fill out this field.",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Please enter a valid email address."
                                            },
                                            maxLength:
                                            {
                                                value: '50',
                                                message: 'Maximum 50 characters are allowed.'
                                            }
                                        })}
                                    />
                                    {errors.email && <label className="error">
                                        {errors.email.message}
                                    </label>}
                                </div>
                                <div className='col-lg-12  form-group'>
                                    <Form.Label>Contact Number<span>*</span></Form.Label>

                                    <Controller
                                        name="phoneNumber"
                                        control={control}

                                        rules={{
                                            required: "Please fill out this field.",
                                            minLength:
                                            {
                                                value: '15',
                                                message: 'Please enter a valid phone number.'
                                            },
                                        }}
                                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                            <PhoneInput
                                                enableSearch
                                                onBlur={onBlur}
                                                country={'in'}

                                                ref={phoneRef}

                                                countryCodeEditable={false}
                                                onChange={(value, data, event, formattedValue) => {
                                                    setValue("phoneNumber", formattedValue, {
                                                        shouldValidate: true,
                                                        shouldDirty: true
                                                    });
                                                }}

                                            />
                                        )}
                                    />
                                    {errors.phoneNumber && <label className="error">
                                        {errors.phoneNumber.message}
                                    </label>}

                                </div>

                                <div className='col-lg-12 form-group'>
                                    <Form.Label>Which Position you want to join for?</Form.Label>
                                    <Form.Control type="text" name='position'
                                        {...register("position", {
                                            required: "Please fill out this field.",
                                            maxLength:
                                            {
                                                value: '50',
                                                message: 'Maximum 50 characters are allowed.'
                                            }
                                        })}
                                    />
                                    {errors.email && <label className="error">
                                        {errors.email.message}
                                    </label>}

                                </div>

                                <div className="col-lg-12 form-group">

                                    <Form.Label>Upload Resume* (PDF)</Form.Label>
                                    <div className="form-control file-upload custom_input" >
                                        <span className="fake-btn ">{selectedFile ? selectedFile.name : 'Choose File'}</span>

                                        {selectedFile === null &&
                                            <input className="file-input file_upload_field custom_input form-control-sm form-control"
                                                type="file"
                                                name="resume"
                                                id="chooseFile"
                                                accept='application/pdf'

                                                {...register('resume', {
                                                    onChange: handleFileSelect,

                                                    required: "Please select your resume.",
                                                    validate: {
                                                        checkFileType: file => {
                                                            const acceptedFileTypes = ['application/pdf']; // adjust this as needed
                                                            if (file && acceptedFileTypes.includes(file[0].type)) {
                                                                return true;
                                                            } else {
                                                                return 'Invalid file type';
                                                            }
                                                        },
                                                        checkFileSize: file => {
                                                            const maxFileSize = 1000000; // 1MB - adjust as needed
                                                            if (file && file[0].size <= maxFileSize) {
                                                                return true;
                                                            } else {
                                                                return 'File size exceeds limit';
                                                            }
                                                        }
                                                    }
                                                })}

                                            />
                                        }
                                        {selectedFile === null ? <span className="material-symbols-outlined">
                                            upload
                                        </span> : <span className="material-symbols-outlined" onClick={() => setSelectedFile(null)}>
                                            delete
                                        </span>}
                                    </div>

                                    {errors.resume && <label className="error">
                                        {errors.resume.message}
                                    </label>}
                                </div>
                                {/* <div className='col-lg-12 form-group'>
                                    <Form.Label>Upload Resume<span>*</span>&nbsp;(PDF)</Form.Label>
                                    <div className="file-upload">
                                        <div className="file-select form-control">
                                            <div className="file-select-button" id="fileName">          Choose File </div>
                                            <div className="file-select-name" id="noFile">          {selectedFile ? selectedFile.name : '          Choose File      '}                   </div>

                                            {selectedFile === null &&
                                                <input type="file"
                                                    name="resume" id="chooseFile"
                                                    accept='application/pdf'

                                                    {...register('resume', {
                                                        onChange: handleFileSelect,

                                                        required: "Please select your resume.",
                                                        validate: {
                                                            checkFileType: file => {
                                                                const acceptedFileTypes = ['application/pdf']; // adjust this as needed
                                                                if (file && acceptedFileTypes.includes(file[0].type)) {
                                                                    return true;
                                                                } else {
                                                                    return 'Invalid file type';
                                                                }
                                                            },
                                                            checkFileSize: file => {
                                                                const maxFileSize = 1000000; // 1MB - adjust as needed
                                                                if (file && file[0].size <= maxFileSize) {
                                                                    return true;
                                                                } else {
                                                                    return 'File size exceeds limit';
                                                                }
                                                            }
                                                        }
                                                    })}
                                                />

                                            }

                                            {selectedFile === null ? <span className="material-symbols-outlined ml-auto">
                                                upload
                                            </span> : <span className="material-symbols-outlined ml-auto" onClick={() => setSelectedFile(null)}>
                                                delete
                                            </span>}
                                        </div>

                                    </div>
                                    {errors.resume && <label className="error">
                                        {errors.resume.message}
                                    </label>}

                                </div> */}
                            </div>
                            <Button className='apply_btn' type='submit' >
                                upload NOW
                            </Button>
                        </Form>

                    </Modal.Body>
                </Modal >

                :
                <Modal show={show} onHide={hideModal} centered>
                    <Modal.Header>
                        {jobData?.title &&
                            (
                                <Modal.Title>{jobData?.title}</Modal.Title>

                            )}

                        <Button aria-hidden="true" onClick={hideModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 24 24">
                                <g id="Group_3024" data-name="Group 3024" transform="translate(-1727 -51)">
                                    <g id="Group_3023" data-name="Group 3023" transform="translate(-0.097 -0.139)">
                                        <path id="Path_21074" data-name="Path 21074" d="M-3824.9,5085.139l15.754,15.754" transform="translate(5556 -5030)" fill="none" stroke="#fff" strokeWidth="2" />
                                        <path id="Path_21075" data-name="Path 21075" d="M-3824.9,5085.139l15.754,15.754" transform="translate(6831.99 3880.042) rotate(90)" fill="none" stroke="#fff" strokeWidth="2" />
                                    </g>
                                    <rect id="Rectangle_402" data-name="Rectangle 402" width="24" height="24" transform="translate(1727 51)" fill="none" />
                                </g>
                            </svg>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        {jobData?.description &&
                            (
                                <>
                                    <Card.Title>Job Description</Card.Title>
                                    <Card.Text dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            jobData?.description
                                        ),
                                    }}>

                                    </Card.Text>
                                </>
                            )
                        }

                        <Form className='collaborate_form' onSubmit={handleSubmit(onSubmit)}>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 form-group pr-md-2'>
                                    <Form.Label>Full Name<span>*</span></Form.Label>
                                    <Form.Control type='text'
                                        name='fullName'
                                        {...register("fullName", {
                                            required: "Please fill out this field.",
                                            pattern: {
                                                value: /^[a-zA-Z ]*$/,
                                                message: "Only alphabetical is allowed in name field."
                                            },
                                            minLength:
                                            {
                                                value: '3',
                                                message: 'Minimum 3 characters are allowed.'
                                            },
                                            maxLength:
                                            {
                                                value: '50',
                                                message: 'Maximum 50 characters are allowed.'
                                            }
                                        })} />
                                    {errors.fullName && <label className="error">
                                        {errors.fullName.message}
                                    </label>}
                                </div>
                                <div className='col-lg-6 col-md-6 form-group pl-md-2'>
                                    <Form.Label>Email<span>*</span></Form.Label>
                                    <Form.Control type='text'
                                        name='email'
                                        {...register("email", {
                                            required: "Please fill out this field.",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Please enter a valid email address."
                                            },
                                            maxLength:
                                            {
                                                value: '50',
                                                message: 'Maximum 50 characters are allowed.'
                                            }
                                        })}
                                    />
                                    {errors.email && <label className="error">
                                        {errors.email.message}
                                    </label>}

                                </div>
                                <div className='col-lg-6 col-md-6 form-group pr-md-2'>
                                    <Form.Label>Contact Number<span>*</span></Form.Label>
                                    <Controller
                                        name="phoneNumber"
                                        control={control}

                                        rules={{
                                            required: "Please fill out this field.",
                                            minLength:
                                            {
                                                value: '15',
                                                message: 'Please enter a valid phone number.'
                                            },
                                        }}
                                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                            <PhoneInput
                                                enableSearch
                                                onBlur={onBlur}
                                                country={'in'}

                                                countryCodeEditable={false}
                                                onChange={(value, data, event, formattedValue) => {
                                                    setValue("phoneNumber", formattedValue, {
                                                        shouldValidate: true,
                                                        shouldDirty: true
                                                    });
                                                }}

                                            />
                                        )}
                                    />
                                    {errors.phoneNumber && <label className="error">
                                        {errors.phoneNumber.message}
                                    </label>}
                                </div>
                                {/* <div className='col-lg-6 col-md-6 form-group pl-md-2'>
                                    <Form.Label>Upload Resume<span>*</span></Form.Label>
                                    <div className="file-upload">
                                        <div className="file-select form-control">
                                            <div className="file-select-button" id="fileName">          Choose File        </div>
                                            <div className="file-select-name" id="noFile">          {selectedFile ? selectedFile.name : '          Choose File      '}                   </div>

                                            {selectedFile === null &&
                                                <input type="file"
                                                    name="resume" id="chooseFile"
                                                    accept='application/pdf'
                                                    {...register('resume', {
                                                        onChange: handleFileSelect,
                                                        required: "Please select your resume.",

                                                        validate: {
                                                            checkFileType: file => {
                                                                const acceptedFileTypes = ['application/pdf']; // adjust this as needed
                                                                if (file && acceptedFileTypes.includes(file[0].type)) {
                                                                    return true;
                                                                } else {
                                                                    return 'Invalid file type';
                                                                }
                                                            },
                                                            checkFileSize: file => {
                                                                const maxFileSize = 1000000; // 1MB - adjust as needed
                                                                if (file && file[0].size <= maxFileSize) {
                                                                    return true;
                                                                } else {
                                                                    return 'File size exceeds limit';
                                                                }
                                                            }
                                                        }
                                                    })}
                                                />
                                            }
                                            {selectedFile === null ? <span className="material-symbols-outlined ml-auto">
                                                upload
                                            </span> : <span className="material-symbols-outlined ml-auto" onClick={() => setSelectedFile(null)}>
                                                delete
                                            </span>}

                                        </div>

                                    </div>
                                    {errors.resume && <label className="error">
                                        {errors.resume.message}
                                    </label>}
                                </div> */}

                                <div className="col-lg-6 col-md-6 form-group pl-md-2">

                                    <Form.Label>Upload Resume* (PDF)</Form.Label>
                                    <div className="form-control file-upload custom_input" >
                                        <span className="fake-btn mobile_fake_msg" >{selectedFile ? selectedFile.name : 'Choose File'}</span>

                                        {selectedFile === null &&
                                            <input className="file-input file_upload_field custom_input form-control-sm form-control"
                                                type="file"
                                                name="resume"
                                                id="chooseFile"
                                                accept='application/pdf'

                                                {...register('resume', {
                                                    onChange: handleFileSelect,

                                                    required: "Please select your resume.",
                                                    validate: {
                                                        checkFileType: file => {
                                                            const acceptedFileTypes = ['application/pdf']; // adjust this as needed
                                                            if (file && acceptedFileTypes.includes(file[0].type)) {
                                                                return true;
                                                            } else {
                                                                return 'Invalid file type';
                                                            }
                                                        },
                                                        checkFileSize: file => {
                                                            const maxFileSize = 1000000; // 1MB - adjust as needed
                                                            if (file && file[0].size <= maxFileSize) {
                                                                return true;
                                                            } else {
                                                                return 'File size exceeds limit';
                                                            }
                                                        }
                                                    }
                                                })}

                                            />
                                        }
                                        {selectedFile === null ? <span className="material-symbols-outlined">
                                            upload
                                        </span> : <span className="material-symbols-outlined" onClick={() => setSelectedFile(null)}>
                                            delete
                                        </span>}
                                    </div>

                                    {errors.resume && <label className="error">
                                        {errors.resume.message}
                                    </label>}
                                </div>

                                <div className='col-12'>
                                    <div className='form-group'>
                                        <textarea placeholder='Message'  {...register("message", {
                                            minLength:
                                            {
                                                value: '5',
                                                message: 'Minimum 5 characters are allowed.'
                                            },
                                            maxLength:
                                            {
                                                value: '500',
                                                message: 'Maximum 500 characters are allowed.'
                                            }
                                        })} rows={5}></textarea>
                                        {errors.message && <label className="error">
                                            {errors.message.message}
                                        </label>}
                                    </div>
                                </div>
                            </div>
                            <Button className='apply_btn' type='submit'>
                                APPLY
                            </Button>
                        </Form>

                    </Modal.Body>
                </Modal>


            }
            {loader && <Loader />}
        </>
    )
}

export default JoinUsForm



