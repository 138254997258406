import React from 'react';
import "../PageNotFound/pagenotfound.css";
import { Link } from "react-router-dom";
import nascent from "../TheCompany/GetInTouch/nascent.png";

const PageNotFound = () => {
  return (
    <>

      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='error_show'>


              <div className='page_error'>
                <h1>404</h1>
                <h4>Not found</h4>
              </div>
              <div className='page_error'>
                <Link to="/">
                  <div className='nascent_logo'>
                    <img src={nascent} alt="Nascent Logo" />
                  </div>

                  <p>Get Back to Home</p>
                </Link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default PageNotFound
