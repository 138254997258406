import React from 'react';
import "../swipertabcontent/swipertabcontent.css";
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';

const SwiperTabContent = ({ data }) => {

    return (
        <>
            {/* Start Swiper Content Portion Code Here  */}
            <div className='swiper_content'>
                <h5>{data.strTitle}</h5>
                <p dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                        data.strHomeDescription
                    )
                }}></p>
                <Link to={"/services"} state={data.strTitle}>Learn more&nbsp;
                    <svg xmlns="http://www.w3.org/2000/svg" width="18.013" height="18.013" viewBox="0 0 18.013 18.013">
                        <g id="arrow_back_black_24dp" transform="translate(-1.644 18.013) rotate(-90)" opacity="0.997">
                            <path id="Path_41" data-name="Path 41" d="M0,0H18.013V18.013H0Z" transform="translate(0 1.644)" fill="none" />
                            <path id="Path_42" data-name="Path 42" d="M2.875,5.254l4.2-4.2L6,0,0,6l6,6,1.058-1.058-4.188-4.2L2.144,6Z" transform="translate(3.003 14.186) rotate(-90)" fill="#007bff" />
                        </g>
                    </svg>
                </Link>
            </div>
            {/* End Swiper Content Portion Code Here  */}
        </>
    )
}

export default SwiperTabContent
