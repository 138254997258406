import React from 'react';
import "../Testimonials/testimonials.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import testimonial from "../Testimonials/testimonial.jpg";
import testimonial2 from "../Testimonials/testimonial2.jpg";
import testimonial3 from "../Testimonials/testimonial3.jpg";
import startquote from "../Testimonials/startquote.png";
import endquote from "../Testimonials/endquote.png";
import DOMPurify from 'dompurify';


const Testimonials = ({ data }) => {
    // Start Service Testimonial Owl Carousel Responsive Code
    const servicetestimonial = {
        loop: true,
        margin: 0,
        nav: true,
        // smartSpeed: 3000,
        autoplaySpeed: 6000,
        // autoplaytimeout: 1500,
        // slidetransition: "linear",
        // autoplay: true,
        autoplayTimeout: 6000,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
        // autoplayHoverPause: true,

        responsive: {
            0: {
                items: 1,
                // stagePadding: 10,
                // autoplay: false,
            },
            // 768: {
            //     items: 2,
            //     // stagePadding: 100,
            // },

        },
    };
    // End Service Testimonial Owl Carousel Responsive Code

    const CarouselItem = ({ htmlContent }) => {
        return (

            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />

        );
    };

    return (

        data.length > 0 && (
            <>
                <div className='testimonial_carousel'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <h5>Testimonials</h5>
                            </div>
                            <div className='col-12'>


                                <OwlCarousel className="owl-theme" {...servicetestimonial} autoplaySpeed={2500}
                                    autoplayTimeout={3000}
                                // slidetransition={linear}
                                >
                                    {data?.map(data => (
                                        <div className="item" key={data.id}>
                                            <div className='container-fluid px-0'>
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <div className='testimonial_descp'>
                                                            <div>
                                                                <span className='startquote quote'>
                                                                    <img src={startquote} alt="Start Quote" />
                                                                </span>
                                                                <CarouselItem key={data.id} htmlContent={data.strDetail} />

                                                                <span className='endquote quote'>
                                                                    <img src={endquote} alt="End Quote" />
                                                                </span>

                                                            </div>
                                                            <span dangerouslySetInnerHTML={{
                                                                __html: DOMPurify.sanitize(
                                                                    data.strName
                                                                )
                                                            }} >

                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className='testimonial_img'>
                                                            <img src={data.strImage} alt="Testimonial Profile" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel>
                            </div>
                        </div>
                    </div >
                </div >
            </>
        )




    )
}

export default Testimonials
