import React from 'react';
import "../Nascenttabs/tabcontent.css";
import "../Nascenttabs/tabcontentresponsive.css";
import social from "../Nascenttabs/social.jpg";
import { useNavigate } from 'react-router-dom';

const TabContent = ({ type = "", data }) => {
    const navigate = useNavigate();

    const goToDetail = (slug, type) => {
        if (type == "blog") {
            navigate(`/blog/${slug}`)
        } else {
            navigate(`/paper/${slug}`)
        }
    }
    return (
        <>
            {/* Start Nascent Latest Tab Content Portion Code Here */}


            {type != "social" ?
                <>
                    <div className='tab_content' onClick={() => goToDetail(data[0].slug, data[0].type)} >
                        <div className='tab_heading'>
                            <h6>{data ? data[0]?.title : ""}</h6>
                            <p>{data ? data[0]?.description : ""}</p>
                        </div>
                        <div className='tab_content_img'>
                            <img src={data ? data[0]?.featureImage : ""} className='w-100' alt="Latest Nascent Img" />
                        </div>
                    </div>
                </> :

                <>
                    <div className='social_content'>
                        <img src={data?.featureImage} className='w-100' alt='Social Img' />
                        <div className='tab_heading'>
                            {data?.title && <h6>{data.title}</h6>}
                            {data?.description && <p>{data?.description}</p>}

                        </div>
                    </div>

                </>
            }






            {/* End Nascent Latest Tab Content Portion Code Here */}
        </>
    )
}

export default TabContent
