import React, { useState, useEffect } from 'react';
import "../CaseStudies/casestudies.css";
import "../CaseStudies/casestudiesresponsive.css";
import { Link, useLocation } from "react-router-dom";
import CaseStudyCard from './CaseStudyCard';
import $ from 'jquery'
import { useDispatch, useSelector } from 'react-redux';
import { fetchPostCategoryData, saveDataToAPI } from "../../services/actions/apiActions";
import Error from '../../ErrorPage/Error';



// End Active Tab Active class Add Portion Code Here 
const CaseStudies = () => {
  const { state } = useLocation();
  const [content, setContent] = useState('');
  const dispatch = useDispatch();
  const categoryData = useSelector((state) => state.postCategory.data.content);
  const error = useSelector((state) => state.apiData.error);
  const data = useSelector((state) => state.apiData.data.data);

  const [searchWords, setSearchWords] = useState("");

  useEffect(() => {
    dispatch(fetchPostCategoryData())
    dispatch(saveDataToAPI("post"));
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0 });
    });
  }, []);

  useEffect(() => {
    var filteredContent = [];
    if (data?.length > 0) {
      data.filter(items => items.types?.map(element => {
        if (element.toLowerCase().split(" ").join('') == "casestudy") {
          filteredContent = [...filteredContent, items];
          setContent(filteredContent)
        }
      }))
    }
  }, [data, searchWords])



  // Start Active Tab Active class Add Portion Code Here 
  const [activeItem, setActiveItem] = useState('All'); // Set the initial active item

  const handleItemClick = (activeTab) => {
    setActiveItem(activeTab);

  };
  useEffect(() => {

    var caseStudyContent = [];
    var categoryContent = []
    if (data?.length > 0) {
      data.filter(items => items.types?.map(element => {
        if (element.toLowerCase().split(" ").join('') == "casestudy") {
          caseStudyContent = [...caseStudyContent, items];
        }
      }))
      if (activeItem == "All") {
        setContent(caseStudyContent)
      } else {
        caseStudyContent.filter(items => items.categories.map(element => {
          if (element.toLowerCase().includes(activeItem.toLowerCase())) {
            categoryContent = [...categoryContent, items];
            setContent(categoryContent)
          }
        }))
      }


    }
  }, [activeItem, data])
  useEffect(() => {
    if (state?.data) {
      setActiveItem(state.data)
    }
  }, [activeItem, state])

  const handleSearchChange = (e) => {
    setSearchWords(e.target.value);
    if (e.target.value.length > 0) {
      $("#close_icon").removeClass('d-none');
      $("#search-icon").addClass('d-none');

    } else {
      $("#close_icon").addClass('d-none')
      $("#search-icon").removeClass('d-none')
      dispatch(saveDataToAPI("post"));
      setDataNotFound(false);
      setActiveItem("All")
    }

  }

  const clearSearch = () => {
    setSearchWords("");
    $("#search-icon").removeClass('d-none')
    $("#close_icon").addClass('d-none');
    setContent(content)
  }
  const [dataNotFound, setDataNotFound] = useState(false);

  useEffect(() => {

    if (searchWords == "") {
      dispatch(saveDataToAPI("post"));
    } else {

      const filteredData = content.filter(items => items.title.toLowerCase().includes(searchWords.toLowerCase()));
      if (filteredData == "") {
        setDataNotFound(true);
        setContent(filteredData)
      } else {
        setDataNotFound(false);
        setContent(filteredData)
      }
    }
  }, [searchWords])


  return (
    // isLoading ? <div>Loading...</div> : error ? <div>Error: {error.message}</div> :
    error ? <Error msg={error.message} /> :

      <>
        {/* Start Case Studies Home Page Portion Code Here  */}
        {/* Start Tabs Portion Code Here  */}
        <div className='case_studies_home'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <p>CASE STUDIES</p>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search case studies"
                    aria-label="Search"
                    aria-describedby="search-icon"
                    value={searchWords}
                    maxLength={"50"}
                    onChange={(e) => handleSearchChange(e)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text" id="search-icon">

                      <svg id="search_black_24dp_1_" data-name="search_black_24dp (1)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                        <path id="Path_39" data-name="Path 39" d="M0,0H24V24H0Z" fill="none" />
                        <path id="Path_40" data-name="Path 40" d="M15.5,14h-.79l-.28-.27a6.51,6.51,0,1,0-.7.7l.27.28v.79l5,4.99L20.49,19Zm-6,0A4.5,4.5,0,1,1,14,9.5,4.494,4.494,0,0,1,9.5,14Z" fill="#313131" />
                      </svg>

                    </span>


                    <span className="input-group-text d-none" id="close_icon" onClick={clearSearch}>

                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 24 24"><g id="Group_3024" data-name="Group 3024" transform="translate(-1727 -51)"><g id="Group_3023" data-name="Group 3023" transform="translate(-0.097 -0.139)"><path id="Path_21074" data-name="Path 21074" d="M-3824.9,5085.139l15.754,15.754" transform="translate(5556 -5030)" fill="none" stroke="#313131" stroke-width="2"></path><path id="Path_21075" data-name="Path 21075" d="M-3824.9,5085.139l15.754,15.754" transform="translate(6831.99 3880.042) rotate(90)" fill="none" stroke="#313131" stroke-width="2"></path></g><rect id="Rectangle_402" data-name="Rectangle 402" width="24" height="24" transform="translate(1727 51)" fill="none"></rect></g></svg>

                    </span>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div className='case_tab'>

                  <ul className="navbar-list">
                    <li
                      className={`navbar-item ${activeItem == "All" ? 'active' : ""}`}
                      onClick={() => handleItemClick("All")}
                    >
                      <Link>
                        {"All"}
                      </Link>
                    </li>
                    {categoryData?.map((data, i) => (
                      <li
                        key={data.id}
                        className={`navbar-item ${activeItem === data.name ? 'active' : ''}`}
                        onClick={() => handleItemClick(data.name)}
                      >
                        <Link>
                          {data.name}
                        </Link>
                      </li>
                    ))}
                  </ul>

                </div>
              </div>
            </div>

            <div className='row'>
              {dataNotFound ?

                <div className='col-12 text-center'>
                  <h6 className='case_not_found'>Case Studies Not Found</h6>
                </div>
                : ""
              }

              {content ? content.map((data, i) => (
                <CaseStudyCard data={data} classStyle={"fade-in-top"} />
              )) : ""}
            </div>
          </div>

        </div>
        {/* End Tabs Portion Code Here  */}
        {/* End Case Studies Home Page Portion Code Here  */}
      </>
  )
}


export default CaseStudies
