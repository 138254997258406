import React, { useEffect, useRef } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';
import useApiSave from '../../services/useApiSave';

function NewsLetter() {
    const {
        reset,
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors
    } = useForm();


    const { isLoading, error, resData, saveData } = useApiSave();

    const onSubmit = async (data) => {
        saveData("newsletter", data);
        if (resData?.status == 200) {
            swal(
                "News Letter Subscribed !",
                "You will get email from nascent.",
                "success"
            );
            reset();
        }
        else if (resData.status == 203) {
            setError('strEmail', { type: 'unique', message: 'Your email is alreday exist in newsletter.' });
        } else {
            setError('strEmail', { type: 'unique', message: '' });
        }
        if (error) {

            swal(
                "Server Error",
                "Data not saved",
                "error"
            );
        }
    }


    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            clearErrors()
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    // return { ref, isComponentVisible, setIsComponentVisible };



    return (
        <>
            <div className='newsletter' ref={ref}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Newsletter</Form.Label>
                        <div className='newsletter_input'>
                            <Form.Control type="text" name='strEmail' className='form-control-sm' placeholder="Enter your e-mail to stay updated"
                                {...register("strEmail", {
                                    required: "Please fill out this field.",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Please enter a valid email address."
                                    },
                                    maxLength:
                                    {
                                        value: '50',
                                        message: 'Maximum 50 characters are allowed.'
                                    }
                                })}
                            />
                            {errors.strEmail &&
                                <Form.Text className="text-danger">
                                    {errors.strEmail.message}
                                </Form.Text>}

                            <Button type='submit' className='btn-sm news_arrow' >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.567 14.922">
                                    <g id="Group_184" data-name="Group 184" transform="translate(-1619.174 -1556.049)">
                                        <path id="Path_73" data-name="Path 73" d="M-3576.721-9233.079h51.506" transform="translate(5195.895 10796.589)" fill="none" stroke="#fff" strokeWidth="1.5" />
                                        <path id="Path_74" data-name="Path 74" d="M-3495.606-9264.768l6.93,6.931-6.93,6.931" transform="translate(5159.356 10821.347)" fill="none" stroke="#fff" strokeWidth="1.5" />
                                    </g>
                                </svg>
                            </Button>
                        </div>

                    </Form.Group>


                </Form>
            </div >
        </>
    )
}

export default NewsLetter