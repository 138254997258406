import React, { useEffect, useState, useRef } from 'react';
import "../VideoBanner/videobanner.css";
// import videoposter from "../VideoBanner/videoposter.jpg";
import videoposter from "../VideoBanner/bannerimg.jpg";
import video from "../VideoBanner/home.mp4";
// import video from "../TheCompany/home_18Oct.mp4";

const VideoBanner = ({ videoPlay }) => {

    // Start Play & Pause Video Code Here 
    const [play, setPlay] = useState(false);
    const vidRef = useRef(null);
    const playHandle = () => {
        // 👇️ toggle isActive state on click
        setPlay(current => !current);
        vidRef.current.play();
        setIsActive(true);
    };



    const pauseHandle = () => {
        setPlay(false);
        vidRef.current.pause();
        setIsActive(false);
    }



    useEffect(() => {
        if (videoPlay === true) {
            vidRef &&
                vidRef.current &&
                vidRef.current.play().catch(error => {
                    console.error("Error attempting to play", error);
                });
            setIsActive(true);
            setPlay(true);
        } else {
            setIsActive(false);
            setPlay(false);
            vidRef &&
                vidRef.current &&
                vidRef.current.pause();
        }

    }, [videoPlay]);
    // ENd Play & Pause Video Code Here 

    // Start Video Button  Mouse Move Hide & Show Portion Code Here 
    const [justHidden, setJustHidden] = useState(false);

    useEffect(() => {
        let timeoutId;

        const handleMouseMove = () => {
            if (!justHidden) {
                setJustHidden(false);
                clearTimeout(timeoutId);
                const buttons = document.querySelectorAll('.video_btn');
                buttons.forEach(div => div.classList.remove('hidden'));
                timeoutId = setTimeout(hide, 2000);
            }
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            clearTimeout(timeoutId);
        };
    }, [justHidden]);


    const hide = () => {
        const buttons = document.querySelectorAll('.video_btn');
        buttons.forEach(div => div.classList.add('hidden'));
    };
    // End Video Button  Mouse Move Hide & Show Portion Code Here 


    //    Start The Company Remove Overlay Code Here 
    const [isActive, setIsActive] = useState(false);
    //    End The Company Remove Overlay Code Here 



    return (
        <>
            {/* Start Video Banner Portion Code Here   */}

            <div className='video_banner'>
                <video
                    playsInline
                    loop

                    muted
                    autoPlay={"true"}
                    // controls={"true"}
                    poster={videoposter}
                    ref={vidRef}

                >                        {/* <source src="/rwoods/static/media/Home1.f5f91b86bbfa5eb7e44c.webm" type="video/web" /> */}
                    <source src={video} type="video/mp4" />

                    Your browser does not support the video tag.</video>

                <div className={`overlay ${isActive ? 'overlay-transparent' : ''}`}>
                    <div className='video_btn_container'>
                        {/* <h6>about the company</h6> */}



                        <div className='video_btn'>
                            <svg xmlns="http://www.w3.org/2000/svg" id='play' viewBox="0 0 96 96" className={play ? 'hide' : ''} onClick={playHandle}>
                                <g id="Group_3050" data-name="Group 3050" transform="translate(-137 -871)">
                                    <g id="Ellipse_83" data-name="Ellipse 83" transform="translate(137 871)" fill="none" stroke="#fff" strokeWidth="1">
                                        <circle cx="48" cy="48" r="48" stroke="none" />
                                        <circle cx="48" cy="48" r="47.625" fill="none" />
                                    </g>
                                    <path id="Polygon_4" data-name="Polygon 4" d="M12.206,1.039a1,1,0,0,1,1.589,0L24.771,15.393A1,1,0,0,1,23.976,17H2.024a1,1,0,0,1-.794-1.607Z" transform="translate(196 906) rotate(90)" fill="#fff" />
                                </g>

                                <title>Play Video</title>

                            </svg>


                            <svg xmlns="http://www.w3.org/2000/svg" id='pause' viewBox="0 0 96 96" className={play ? '' : 'hide'} onClick={pauseHandle}>
                                <g id="Group_3051" data-name="Group 3051" transform="translate(-244 -871)">
                                    <path id="pause_circle_FILL0_wght100_GRAD0_opsz48" d="M158.353-781.472h2.124v-25.87h-2.124Zm12.356,0h2.124v-25.87h-2.124ZM165.593-794.407Z" transform="translate(126.407 1713.407)" fill="#fff" />
                                    <g id="Ellipse_108" data-name="Ellipse 108" transform="translate(244 871)" fill="none" stroke="white" strokeWidth="1">
                                        <circle cx="48" cy="48" r="48" stroke="none" />
                                        <circle cx="48" cy="48" r="47.625" fill="none" />
                                    </g>
                                </g>
                                <title>Pause Video</title>
                            </svg>
                        </div>

                    </div>
                </div>
            </div>
            {/* End Video Banner Portion Code Here   */}
        </>
    )
}

export default VideoBanner
