import { Button } from 'react-bootstrap'
import React from 'react';
import "../OurCulture/ourculture.css";
import culture1 from "../OurCulture/culture1.jpg";
import culture2 from "../OurCulture/culture2.jpg";
import culture3 from "../OurCulture/culture3.jpg";
import { useNavigate } from 'react-router-dom';


const OurCulture = () => {
    const navigate = useNavigate();

    const goToJoinUs = () => {
        navigate("/joinus");
    }
    return (
        <>
            {/* Start Our Culture Portion Code Here   */}
            <div className='our_culture'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='culture_text'>
                                <h2>Our Culture</h2>
                                <p>
                                    We cherish a culture where creativity thrives, and everyone is empowered with the freedom to ideate. We believe in the transformative power of innovation and encourage our team to push the boundaries of what’s possible.
                                </p>
                                <p>
                                    However, alongside the spirit of creativity, we recognize the importance of hard work, dedication and collaboration. We hold ourselves and each other to high standards, understanding that achieving our bigger purpose requires relentless effort and commitment from every team member.
                                </p>
                                <p>
                                    We value both the power of imagination and the sweat of hard work, combining them to achieve extraordinary results. We invite you to be part of this incredible journey as we bring about positive change and shape a brighter future.
                                </p>


                                <div className='join_us_button'>
                                    <Button className='join_us_btn' onClick={goToJoinUs}>JOIN US</Button>
                                </div>
                            </div>

                        </div>
                        <div className='col-lg-4'>
                            <div className='culture_img'>
                                <div className='img_box'>
                                    <img src={culture1} alt="Culture Img" />
                                </div>
                                <div className='img_box'>
                                    <img src={culture2} alt="Culture Img" />
                                </div>
                                <div className='img_box'>
                                    <img src={culture3} alt="Culture Img" />
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Our Culture Portion Code Here   */}
        </>
    )
}

export default OurCulture
