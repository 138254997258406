import React, { useState, useEffect } from 'react';
import "./header.css";
import "./headerresponsive.css";
import nascentlogo from "./nascent-logo.png";
import nascentlogo2 from "./nascentlogowhite.png";
import { Modal, Button, FormControl } from "react-bootstrap";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import $ from "jquery";
import { fetchPostCategoryData } from '../../services/actions/apiActions';
import { useDispatch, useSelector } from 'react-redux';

const Header = () => {
  const location = useLocation();
  // Start Search Modal Code 
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleOpen = () => {
    setShow(true)
    $(" header .navbar .hamRotate").removeClass("active");
    $(" body").removeClass("scrolling-hidden ");
  }
  // End Search Modal Code 

  // Start Scroll Down to Hide and Scroll Up to Show Navaigation Code
  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      let moving = window.pageYOffset;
      setVisible(position > moving);
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    $(".navbar-toggler").removeClass("collapsed");
    $(".navbar-collapse").removeClass(" show");
    $(".navbar-collapse").removeClass(" show");
    $("header .dropdown").removeClass(" show");
    $(" header .dropdown-menu.show").removeClass(" show");
    // return () => {
    //   window.removeEventListener("scroll", handleScroll);
    // };
  });
  const cls = visible ? "visible" : "hidden";
  // End Scroll Down to Hide and Scroll Up to Show Navaigation Code

  //Start Below One Page In Header Background Color Transparent Code
  // const [transparent, setTransparent] = useState(false);
  const [transaprantClass, setTransparentClass] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      let moving = window.pageYOffset;
      setVisible(position > moving);
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    $(".navbar-toggler").removeClass("collapsed");
    $(".navbar-collapse").removeClass("show");

    if (window !== undefined) {
      let windowHeight = window.scrollY;
      if (location.pathname == "/thecompany") {
        if (windowHeight < 700) {
          setTransparentClass(true);
        } else {
          setTransparentClass(false);
        }
      } else {
        setTransparentClass(false);
      }
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });


  const currentActiveNav = location.pathname.replace('/', '');
  const [activeItem, setActiveItem] = useState('');

  useEffect(() => {
    setActiveItem(currentActiveNav);
  });


  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
    $("body").removeClass("scrolling-hidden");
    $('.navbar-toggler').removeClass("collapsed");
    $('.navbar-collapse').removeClass("show");
    $('.hamRotate').removeClass('active')
  };
  const handleLogoClick = () => {
    setActiveItem("");
    $("body").removeClass("scrolling-hidden");
    $('.navbar-toggler').removeClass("collapsed");
    $('.navbar-collapse').removeClass("show");
    $('.hamRotate').removeClass('active')
  };
  const categoryData = useSelector((state) => state.postCategory.data.content);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPostCategoryData())

  }, [dispatch]);
  const navigate = useNavigate();
  const naviagateToService = (type) => {
    navigate('/services', { state: type });;
    $("body").removeClass("scrolling-hidden");
    $('.navbar-toggler').removeClass("collapsed");
    $('.navbar-collapse').removeClass("show");
    $('.hamRotate').removeClass('active')
  }

  return (
    <>
      {/* Start Header Portion Code Here */}
      <header className={`${cls} ${transaprantClass === true ? "navTransparent" : ""}`}>

        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <nav className="p-0 navbar navbar-expand-lg navbar-light ">
                <Link className='nascent_logo navbar-brand logo_color' to="/" onClick={handleLogoClick}>
                  <img src={nascentlogo} alt="Nascent Logo" />
                </Link>
                <Link className='nascent_logo navbar-brand logo_white' to="/" onClick={handleLogoClick}>
                  <img src={nascentlogo2} alt="Nascent Logo" />
                </Link>
                {/* <Link className='nav-link mobile_search' onClick={handleOpen}><i className="fas fa-search"></i></Link> */}
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <svg className="ham hamRotate ham1" viewBox="0 0 100 100">
                    <path
                      className="line top"
                      d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40" />
                    <path
                      className="line middle"
                      d="m 30,50 h 40" />
                    <path
                      className="line bottom"
                      d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40" />
                  </svg>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav ml-auto">
                    <li className={`nav-item ${activeItem === 'thecompany' ? 'active' : ''}`}>
                      <Link className='nav-link' to="/thecompany" onClick={() => handleItemClick('thecompany')}>The Company</Link>
                    </li>
                    <li className="nav-item dropdown">
                      <span className='nav-link' role="button" data-toggle="dropdown" aria-expanded="false">Services</span>
                      <ul className="dropdown-menu">
                        {categoryData?.map((data, i) => (
                          <>
                            <li onClick={() => naviagateToService(data.name)} key={i}><Link className="dropdown-item" key={data.id}>{data.name}</Link></li>
                          </>
                        ))}
                      </ul>
                    </li>

                    {/* Start Please Do Not Remove Below Comment Li Tag Its Sample Code Important  */}

                    {/* <li className={`nav-item dropdown ${activeItem === 'services' ? 'active' : ''}`}>
                      <span className='nav-link' role="button" data-toggle="dropdown" aria-expanded="false">Services</span>
                      <ul className="dropdown-menu">
                        {categoryData?.map((data, i) => (
                          <>
                            <li onClick={() => naviagateToService(data.name)} key={i}><Link className="dropdown-item" key={data.id}>{data.name}</Link></li>
                          </>
                        ))}
                      </ul>
                    </li> */}

                    {/* End Please Do Not Remove Below Comment Li Tag Its Sample Code Important  */}

                    <li className={`nav-item ${activeItem == 'insights' ? 'active' : ''}`}>
                      <Link className='nav-link' to="/insights" onClick={() => handleItemClick('insights')}>Insights</Link>
                    </li>
                    <li className={`nav-item ${activeItem == 'casestudies' ? 'active' : ''}`}>
                      <Link className='nav-link' to="/casestudies" onClick={() => handleItemClick('casestudies')}>Case Studies</Link>
                    </li>
                    {/* <li className={`nav-item ${activeItem == 'products' ? 'active' : ''}`}>
                      <Link className='nav-link' onClick={() => handleItemClick('products')}>Products</Link>
                    </li> */}
                    <li className={`nav-item dropdown ${activeItem === 'products' ? 'active' : ''}`}>
                      <Link className='nav-link' onClick={() => handleItemClick('products')} data-toggle="dropdown" aria-expanded="false">Products</Link>
                      <ul className="dropdown-menu">
                        <li><Link className="dropdown-item" to="https://www.nascentinfo.com/citylayers/index.html" target='_blank'>Layers</Link></li>
                        {/* <li><Link className="dropdown-item" to="/n-collector">N-Collector</Link></li> */}

                      </ul>
                    </li>
                    <li className={`nav-item ${activeItem == 'joinus' ? 'active' : ''}`}>
                      <Link className='nav-link' to="/joinus" onClick={() => handleItemClick('joinus')}>Join Us</Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link className='nav-links desktop_search' onClick={() => setShow(true)}><i className="fas fa-search"></i></Link>
                    </li> */}
                  </ul>

                </div>
              </nav>

            </div>
          </div>
        </div>
      </header >

      {/* Start Search Modal Portion Code Here  */}
      <Modal
        className='p-0'
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-100w search_modal"
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static">
        <Modal.Body>
          {/* <Button type="button" className="close" onClick={handleClose}> */}
          <div className='container'>
            <div className='row'>
              <div className='col-12 '>
                <div className='search_box'>

                  <FormControl type="text" placeholder="Search anything" className="mr-sm-2" size="sm" />
                  <div className='search_button'>
                    <Button className='btn-sm search_btn'>
                      <svg id="search_black_24dp_1_" data-name="search_black_24dp (1)" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24">
                        <path id="Path_39" data-name="Path 39" d="M0,0H24V24H0Z" fill="none" />
                        <path id="Path_40" data-name="Path 40" d="M15.5,14h-.79l-.28-.27a6.51,6.51,0,1,0-.7.7l.27.28v.79l5,4.99L20.49,19Zm-6,0A4.5,4.5,0,1,1,14,9.5,4.494,4.494,0,0,1,9.5,14Z" fill="#fff" />
                      </svg>
                    </Button>
                    <Button aria-hidden="true" className="close" onClick={handleClose}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 24 24">
                        <g id="Group_3024" data-name="Group 3024" transform="translate(-1727 -51)">
                          <g id="Group_3023" data-name="Group 3023" transform="translate(-0.097 -0.139)">
                            <path id="Path_21074" data-name="Path 21074" d="M-3824.9,5085.139l15.754,15.754" transform="translate(5556 -5030)" fill="none" stroke="#fff" strokeWidth="2" />
                            <path id="Path_21075" data-name="Path 21075" d="M-3824.9,5085.139l15.754,15.754" transform="translate(6831.99 3880.042) rotate(90)" fill="none" stroke="#fff" strokeWidth="2" />
                          </g>
                          <rect id="Rectangle_402" data-name="Rectangle 402" width="24" height="24" transform="translate(1727 51)" fill="none" />
                        </g>
                      </svg>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* </Button>   */}

        </Modal.Body>
      </ Modal>
      {/* End Search Modal Portion Code Here  */}

      {/* End Header Portion Code Here */}
    </>
  )
}

export default Header
