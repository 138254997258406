import React, { useEffect, useState } from 'react';
import "../CaseStudiesDetail/casestudiesdetail.css";
import { Badge, Card } from 'react-bootstrap';

import "../CaseStudiesDetail/casestudiesdetailresponsive.css";
import { useDispatch, useSelector } from 'react-redux';
import { saveDataToAPI } from '../../../services/actions/apiActions';
import { useLocation, useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Error from '../../../ErrorPage/Error';

const CaseStudiesDetail = () => {
    const location = useLocation();

    const [slug, setSlug] = useState(location.pathname.replace("/casestudy/", ""))

    const [content, setContent] = useState('');
    const dispatch = useDispatch();
    const error = useSelector((state) => state.apiData.error);
    const data = useSelector((state) => state.apiData.data.data);

    useEffect(() => {
        dispatch(saveDataToAPI("post"));
    }, [dispatch]);
    const [similarContent, setSimilarContent] = useState('')


    useEffect(() => {
        if (data?.length > 0) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].slug == slug) {
                    data[i].types.map(element => {
                        if (element.toLowerCase().split(" ").join('').includes("casestudy")) {
                            var filteredData = data[i];

                            filteredData.body = filteredData.body.replace("<p>", "");
                            filteredData.body = filteredData.body.replace("</p>", "");


                            setContent(filteredData)
                        }
                    });
                }

            }
        }
    }, [data, location, slug])

    useEffect(() => {
        var filterContent = [];
        var similarContentFilter = [];
        if (data?.length > 0) {
            data.filter(items => items.types.map(element => {
                if (element.toLowerCase().split(" ").join('') == "casestudy") {
                    filterContent = [...filterContent, items];
                }
            }))
            similarContentFilter = filterContent.filter(items => items.categories.some(element => content?.categories?.includes(element)) && items.title != content.title)
            setSimilarContent(similarContentFilter)
        }
    }, [data, content?.categories])
    // console.log(similarContent)
    //    Start Scroll To Top Start Page Code 
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, left: 0 });
        });
    }, []);

    const navigate = useNavigate();
    const updateSlug = (slug) => {
        navigate(`/casestudy/${slug}`);
        dispatch(saveDataToAPI("post"));
        window.scrollTo({ top: 0, left: 0 });
        setSlug(slug)
    }
    //    End Scroll To Top Start Page Code 



    return (
        error ? <Error msg={error.message} /> :

            <>
                {/* Start Case Studies Detail Portion Code Here  */}
                <div className='case_studies_top'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='case_top'>
                                    <p>CASE STUDIES</p>
                                    <Badge variant="dark">
                                        {content ? content.tags.map((data, i) => (
                                            <span>{data}</span>
                                        )) : ""}
                                    </Badge>
                                    <h5>{content?.title}</h5>
                                    <h6>{content?.description}</h6>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <img src={content?.Image} alt="Case Studies Top" />
                            </div>
                        </div>
                        <div className='row top_mt'>
                            <div className='col-12'
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        content?.body
                                    ),
                                }}
                            >
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Case Studies Detail Portion Code Here  */}

                <div dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                        content?.content
                    ),
                }}>
                </div>


                {/* Start Similar Case Studies Portion Code Here  */}
                {similarContent &&
                    <div className='similar_case_studies'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12 px-0'>
                                    <h2>Similar Case Studies</h2>
                                </div>
                            </div>
                            <div className='row'>
                                {similarContent ? similarContent.map((data, i) => (

                                    i < 3 && (
                                        <div className='col-lg-4 col-md-4' key={data.id}>
                                            <Card onClick={() => updateSlug(data.slug)}>
                                                <div className='similar_img'>
                                                    <Card.Img variant="top" src={data.featureImage} />
                                                    <Card.Title>{data.title}</Card.Title>
                                                </div>

                                            </Card>
                                        </div>
                                    )
                                )) : ""}

                            </div>
                        </div>
                    </div>
                }

                {/* End Similar Case Studies Portion Code Here  */}
            </>
    )
}

export default CaseStudiesDetail
