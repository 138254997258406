import React, { useEffect, useRef, useState } from 'react';
import "../TimelineCarousel/timelinecarousel.css";
import OwlCarousel from "react-owl-carousel";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { saveDataToAPI } from '../../../services/actions/apiActions';

const TimelineCarousel = () => {


    // Start Active Tab Active class Add Portion Code Here 
    const carouselRef = useRef();

    const journeyData = useSelector((state) => state.apiData.data.content);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(saveDataToAPI("getJourneyData"));
    }, [dispatch])

    let sortedData;
    if (journeyData != undefined) {
        sortedData = journeyData?.sort(function (a, b) {
            return a.intYear - b.intYear;
        })

    }


    const [activeItem, setActiveItem] = useState(0);

    useEffect(() => {
        if (activeItem == 0) {
            carouselRef?.current?.to(0, 8)
        }
        else if (activeItem == 1) {
            carouselRef.current.to(1, 8)
        }
        else if (activeItem == 2) {
            carouselRef.current.to(2, 8)
        }
        else if (activeItem == 3) {
            carouselRef.current.to(3, 8)
        }
        else if (activeItem == 4) {
            carouselRef.current.to(4, 8)
        }
        else if (activeItem == 5) {
            carouselRef.current.to(5, 8)
        } else {
            carouselRef.current.to(0, 8)
        }
    }, [activeItem])

    const handleItemClick = (item) => {
        setActiveItem(item)
    };


    // End Active Tab Active class Add Portion Code Here 

    const handleSlideChange = (event) => {

        // console.log(event.item.index)
        // console.log(event.item.index - 2)
        // if (event.item.index - 2 == "0") {
        //     setActiveItem("0")
        // } else if (event.item.index - 2 == "1") {
        //     setActiveItem("1")
        // }
        // else if (event.item.index - 2 == "2") {
        //     setActiveItem("2")
        // } else if (event.item.index - 2 == "3") {
        //     setActiveItem("3")
        // }


    };

    // Start Timeline Owl Carousel Responsive Code
    const timelineCarousel = {

        loop: true,
        center: true,
        // margin: 10,
        autoplay: false,
        // dots: true,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: true,
        // onInitialized: counter,
        // onTranslated: counter,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    };
    // End Timeline Owl Carousel Responsive Code



    return (
        journeyData?.length > 0 && (
            <>
                {/* Start Timeline Carousel Portion Code Here   */}
                <div className='timeline_carousel'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <h2>Our Journey towards Success</h2>
                            </div>
                            <div className='col-12'>
                                <div className='timeline_status'>
                                    <ul>
                                        {sortedData ? sortedData.map((item, index) => (
                                            <li
                                                key={item.id}
                                                className={`timeline-item ${activeItem == index ? 'active' : ''}`}
                                                onClick={() => handleItemClick(index)}


                                            >
                                                <Link>
                                                    {item.intYear}
                                                </Link>
                                                <span className='inner_span'></span>
                                                <span className='circle_img'></span>
                                            </li>
                                        )) : ""}
                                    </ul>
                                </div>
                            </div>

                            <div className='col-12'>
                                <OwlCarousel className="owl-theme" {...timelineCarousel} autoplaySpeed={2500}
                                    autoplayTimeout={3000} ref={carouselRef} mouseDrag={false} onChanged={handleSlideChange}
                                >
                                    {journeyData ? journeyData.map((item, index) => (
                                        <div className="item">
                                            <img src={item.strImages} alt="Timeline img" />
                                            <p>{item.strTitle}</p>
                                        </div>
                                    )) : ""}
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Timeline Carousel Portion Code Here   */}
            </>
        )
    )
}

export default TimelineCarousel
