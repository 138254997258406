import React, { useRef, useEffect, useState } from 'react';
import "../JoinUs/joinus.css";
import firstjoin from "../JoinUs/firstjoin.jpg";
import secondjoin from "../JoinUs/secondjoin.jpg";
import rightjoin from "../JoinUs/rightjoin.jpg";
import { Button, l } from 'react-bootstrap';
import "../JoinUs/joinusresponsive.css";
import { Link, NavLink } from 'react-router-dom';
import JoinUsForm from './JoinUsForm';
import { useDispatch, useSelector } from 'react-redux';
import { saveDataToAPI } from '../../services/actions/apiActions';
import Loader from '../Loader/Loader';

const JoinUs = () => {
    // Start Open Position Scroll Smooth Portion Code 
    const ref = useRef(null);

    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };
    // End Open Position Scroll Smooth Portion Code 

    //    Start Scroll To Top Start Page Code 
    useEffect(() => {

        window.scrollTo({ top: 0, left: 0 });

    }, []);

    //    End Scroll To Top Start Page Code 

    // Start Apply Form Portion Code Here 
    const [show, setShow] = useState(false);
    const [formType, setFormType] = useState("joinus");
    const [modalData, setModalData] = useState(null);
    const applyFormShow = (type, data) => {
        setModalData(data)
        setShow(true);
        setFormType(type)
    }







    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileSelect = (e) => { const file = e.target.files[0]; setSelectedFile(file); };



    const careerPost = useSelector((state) => state.apiData.data.data);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(saveDataToAPI("fetchCareerPosts"));
    }, [dispatch])


    return (
        <>

            {/* Start Join Us Portion Code Here  */}
            <div className='joinus_box'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <p className='join_heading_top'>JOIN US</p>
                        </div>
                        <div className='col-12'>
                            <div className='join_us_heading'>
                                <p>We’re on the lookout for individuals who share our passion for impact. We believe that diversity in backgrounds, skills, and perspectives enriches our community and strengthens our ability to drive positive change.</p>
                                {/* <h5>It’s much more than a job. It’s your turn to</h5>
                                <h2>transform the world.</h2> */}
                            </div>
                        </div>
                    </div>
                    <div className='row'>

                        <div className='col-lg-6 col-md-12'>
                            <div className='join_banner'>
                                <div className='join_us_img'>
                                    <img src={rightjoin} alt="Join Us Img" />
                                </div>
                                <div className='rightBox'>
                                    <div className='join_first_img'>
                                        <img src={firstjoin} alt="Join Us Img" />
                                    </div>
                                    <div className='join_second_img'>
                                        <img src={secondjoin} alt="Join Us Img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-xl-6 col-lg-5 col-md-6 col-6'>
                                   
                                </div> */}

                        <div className='col-lg-6 col-md-12'>
                            <div className='join_us_para'>
                                <h6>We Welcome:</h6>
                                <ul>
                                    <li>
                                        Passionate Advocates: We seek individuals who are deeply passionate about the issues we address and are committed to making a difference.
                                    </li>
                                    <li>
                                        Team Players: Collaboration is at the heart of what we do. We value individuals who can work effectively in teams, support each other, and contribute to a positive working environment.
                                    </li>
                                    <li>
                                        Innovators: We appreciate creative thinkers who can bring fresh ideas and innovative solutions to the challenges we face.
                                    </li>
                                    <li>
                                        Respectful Individuals: We believe in creating a welcoming and inclusive environment for everyone. We expect our members to treat each other with respect, empathy, and kindness.
                                    </li>
                                </ul>
                                <div className='position_btn' onClick={handleClick}>
                                    <Button >open position

                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                            <g id="south_black_24dp" transform="translate(0.438)">
                                                <rect id="Rectangle_314" data-name="Rectangle 314" width="17" height="17" transform="translate(-0.438)" fill="none" />
                                                <path id="Path_323" data-name="Path 323" d="M14.7,10.572l-.977-.93-3.18,3.02V2H9.157V12.663L5.977,9.636,5,10.572l4.85,4.616Z" transform="translate(-2.094 -0.837)" fill="#313131" />
                                            </g>
                                        </svg>

                                    </Button>
                                </div>
                            </div>
                        </div>

                    </div>
                    {careerPost?.length > 0 &&
                        (
                            <div className='row job_portion' ref={ref}>
                                {careerPost?.map(data => (
                                    <div className='col-lg-12' key={data.id}>
                                        <div className='job_box'>
                                            <div className='job_list'>
                                                <h6>{data.title}</h6>
                                                <p>{data.jobType}</p><span className="dot"></span>
                                                {data.pdfFile &&
                                                    <a href={data.pdfFile} target='_blank'>Read more</a>

                                                }
                                            </div>
                                            <div className='job_button' onClick={() => applyFormShow("joinus", data)}>
                                                <Button>apply</Button>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        )
                    }

                </div>
            </div>


            <div className='upload_box'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <p>When you become a part of Nascent, you’re stepping into an environment where we see challenges as opportunities for growth, question the status quo, and constantly push boundaries. You can expect tremendous growth and learning at Nascent.</p>
                            <h5>

                                Didn’t find a suitable opening? Drop us an email at&nbsp;<Link to="mailto:hr@nascentinfo.com">hr@nascentinfo.com</Link>  or <span className='simple'>upload your resume</span> and let us know how you can contribute to our mission. We would be happy to discuss.
                            </h5>
                            {/* <h5>Didn't find a suitable opening? Drop us an email at career@nascentinfo.com and let us know how you can contribute to our mission. We would be happy to discuss.</h5> */}
                            {/* <span>Mail Us&nbsp;-&nbsp;<Link to={"mailto:career@nascentinfo.com"}>career@nascentinfo.com</Link></span> */}

                        </div>
                        <div className='col-md-12 mt-auto mx-auto'>
                            <div className='position_btn' onClick={() => applyFormShow("uploadCv")}>
                                <Button>UPLOAD RESUME
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                        <g id="south_black_24dp" transform="translate(0.438)">
                                            <rect id="Rectangle_314" data-name="Rectangle 314" width="17" height="17" transform="translate(-0.438)" fill="none" />
                                            <path id="Path_323" data-name="Path 323" d="M14.7,10.572l-.977-.93-3.18,3.02V2H9.157V12.663L5.977,9.636,5,10.572l4.85,4.616Z" transform="translate(-2.094 -0.837)" fill="#313131" />
                                        </g>
                                    </svg>
                                </Button>
                            </div>
                            {/* <small className='text-muted'>We'll never share your email with anyone else.</small> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* End Join Us Portion Code Here  */}
            <JoinUsForm show={show} setShow={setShow} type={formType} jobData={modalData} />


        </>
    )
}

export default JoinUs
