import React from 'react';
import "../Layers/layers.css";
import { Card } from 'react-bootstrap';

const Layers = () => {
    return (
        <>
            {/* Start Products Layers Portion Code Here  */}
            <div className='layers_top'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <p className="heading_layers">Products</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='layers_title'>
                                <p><span>Layers&nbsp;:&nbsp;</span>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam volupt ua. At vero eos et sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam era</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* End Products Layers Portion Code Here  */}


            {/* Start N-Collector Portion Code Here  */}
            <div className='n_collector'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-9 mx-auto'>
                            <Card>
                                <div className='row no-gutters'>
                                    <div className='col-md-4'>
                                        <Card.Img variant="top" src="holder.js/100px180" />
                                    </div>
                                    <div className='col-md-8'>
                                        <Card.Body>
                                            <h5>Layers</h5>
                                            <Card.Text>
                                                <span> Open Source Web-GIS Platform</span> A powerful web-based GIS platform designed to transform the way you interact with geospatial data. Publish, visualise, search, discover, edit, and manage maps and geospatial information on an OGC compliant solution built on open source technologies. LAYERS unleashes the full potential of location-based insights, empowering you to make data-driven decisions with precision and confidence.
                                            </Card.Text>
                                            <div className="position_btn">
                                                <button type="button" className="btn btn-primary">EXPLORE MORE</button>
                                            </div>
                                        </Card.Body>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
            {/* End N-Collector Portion Code Here  */}
        </>
    )
}

export default Layers
