import React from 'react';
import "./loader.css";

const Loader = () => {
    return (
        <>
            {/* Start Data Loader Portion Code Here   */}
            <div className="data_loader">
                <div className="ball1"></div>
                <div className="ball2"></div>
                <div className="ball3"></div>
                <div className="ball4"></div>
            </div>
            {/* End Data Loader Portion Code Here   */}
        </>
    )
}

export default Loader
