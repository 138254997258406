import React, { useEffect } from 'react';
import "../PrivacyPolicy/privacypolicy.css";
import { useDispatch, useSelector } from 'react-redux';
import { saveDataToAPI } from '../../services/actions/apiActions';
import DOMPurify from 'dompurify';
import Error from '../../ErrorPage/Error';

const PrivacyPolicy = () => {
    const dispatch = useDispatch();
    const error = useSelector((state) => state.apiData.error);
    const data = useSelector((state) => state.apiData.data.content);
    useEffect(() => {
        dispatch(saveDataToAPI("getprivacypolicydata"));
    }, [dispatch]);
    //    Start Scroll To Top Start Page Code 
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, left: 0 });
        });
    }, []);
    //    End Scroll To Top Start Page Code 
    return (
        error ? <Error msg={error.message} /> :

            <>
                {/* Start Privacy Policy Portion Code Here  */}
                <div className="privacy_policy">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <p className='privacy_heading'>PRIVACY POLICY</p>
                            </div>
                        </div>
                    </div>
                    <div dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            data?.description
                        ),
                    }}>

                    </div>
                </div>
                {/* End Privacy Policy Portion Code Here  */}
            </>
    )
}

export default PrivacyPolicy
