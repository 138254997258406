import React, { useEffect, useState } from 'react';
import axios from 'axios';


const ClientBox = () => {
    const [data, setData] = useState();
    useEffect(() => {
        async function fetchData() {
            try {
                const { data } = await axios.post(`${process.env.REACT_APP_API_SERVER}/getclients`, { token: process.env.REACT_APP_API_TOKEN });
                if (data.status === 200) {
                    setData(data.content)
                }
            } catch (err) {
                console.log(err)
            }

        }
        fetchData();
    }, []);
    //    Start Scroll To Top Start Page Code 
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, left: 0 });
        });
    }, []);
    //    End Scroll To Top Start Page Code 


    return (
        <>
            {/* Start Who we’ve worked with Portion Code Here  */}
            <div className='client_box'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='client_heading'>
                                <h2>Who we’ve worked with?</h2>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        {data?.map((data) => (
                            <div className='col-lg-3 col-sm-6'>
                                <div className='client_img'>
                                    <img src={data.strImage} alt="Client Logo" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* End Who we’ve worked with Portion Code Here  */}
        </>
    )
}

export default ClientBox
