import React, { useEffect, useState } from 'react';
import "../BlogContent/blogcontent.css";
import { useNavigate } from "react-router-dom";



const BlogContent = ({ data }) => {
    const [content, setContent] = useState('');

    useEffect(() => {
        if (data?.length > 0) {
            var filteredContent = [];
            for (let i = 0; i < data.length; i++) {
                data[i]?.types?.map(element => {
                    if (element.toLowerCase().includes("blogs")) {
                        filteredContent = [...filteredContent, data[i]];
                    }
                });
            }
            for (let i = 0; i < filteredContent.length; i++) {
                const wordsPerMinute = 100;
                const contents = filteredContent[i].body;
                const wordCount = contents.split(/\s+/).length;
                const readTimeMinutes = Math.ceil(wordCount / wordsPerMinute);
                filteredContent[i].timeRead = readTimeMinutes
                setContent(filteredContent)
            }
        }
    }, [data]);
    //  Start Navigate To Blog Detail Page 
    let navigate = useNavigate();
    const blogDetailClick = (slug) => {
        navigate(`/blog/${slug}`);
    }
    // End Navigate To Blog Detail Page 

    return (
        <>
            {/* Start Blog Box Portion Code Here  */}
            <div className='blog_content'>
                <div className='container-fluid px-0'>
                    <div className='row'>
                        {content ? content.map((data, i) => (
                            <div className='col-md-6 col-11' key={data.id} onClick={() => blogDetailClick(data.slug)}>
                                <div className='blog_card'>
                                    <div className='blog_layer'>
                                        <img src={data.featureImage} alt="Blog Img" />
                                        <div className='blog_overlay'>
                                            <small className='blog_time'>{data.timeRead} Minute Read</small>
                                            <div className='blog_bottom'>
                                                <h6>{data.title}</h6>
                                                <small className='blog_date'>{data.date}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : ""}

                    </div>
                </div>
            </div>

            {/* End Blog Box Portion Code Here  */}
        </>
    )
}

export default BlogContent
