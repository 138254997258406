import React, { useEffect, useState } from 'react';
import "../Services/services.css";
import "../Services/servicesresponsive.css";
import servicebanner from "../Services/servicebanner.jpg";
import Testimonials from './Testimonials/Testimonials';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { saveDataToAPI } from '../../services/actions/apiActions';
import DOMPurify from 'dompurify';
import Error from '../../ErrorPage/Error';
import ServiceTransform from './ServiceTransform/ServiceTransform';
import ServiceSlider from './ServiceSlider/ServiceSlider';

const Services = () => {
    const { state } = useLocation();
    //    Start Scroll To Top Start Page Code 
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, left: 0 });
        });
    }, [state]);
    //    End Scroll To Top Start Page Code 

    const dispatch = useDispatch();
    const error = useSelector((state) => state.apiData.error);
    const data = useSelector((state) => state.apiData.data);
    const [serviceType, setServiceType] = useState("GeoSolution");

    useEffect(() => {
        dispatch(saveDataToAPI("servicedata"));
    }, [dispatch, serviceType]);
    useEffect(() => {
        if (state === null) {
            setServiceType("GeoSolution")
        } else {
            setServiceType(state)
        }

    }, [state])

    let content;

    if (data) {
        content = data.content?.filter(items => items.strTitle == serviceType);
        var filteredContent = data.content?.filter(items => items.strTitle != serviceType)

    }

    const updateService = (title) => {
        setServiceType(title);
        window.scrollTo({ top: 0, left: 0 });
    }

    return (
        error ? <Error msg={error.message} /> :
            <>
                {/* Start Services Page Portion Code Here   */}
                <div className='service_banner'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-6 col-lg-8'>
                                <div className='service_header'>
                                    <p>SERVICES</p>
                                    <h3>Our areas of </h3>
                                    <h2>Expertise.</h2>
                                </div>
                            </div>
                            <div className='col-sm-6 col-lg-4 mt-auto'>
                                <div className='banner_heading'>
                                    {/* <h6>01. Smart City Digitization</h6> */}
                                    <h6>{content ? content[0]?.strTitle : ""}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12 px-0'>
                                <img src={content ? content[0]?.strBannerImage : ""} alt="Services Banner" />
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <p className='banner_desp' dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        content ? content[0]?.strDescription : ""
                                    ),
                                }} ></p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Services Page Portion Code Here   */}


                {/* Start Service Slider Portion Code Here  */}
                {data?.OfferingInServices &&
                    <ServiceSlider type={serviceType} data={data?.OfferingInServices?.filter(items => items.CategoryName == serviceType)} />
                }
                {/* End Service Slider Portion Code Here  */}



                {/* Start Testimonials Slider portion Code Here  */}
                {data?.Testimonial &&
                    <Testimonials data={data?.Testimonial?.filter(items => items.ServiceType == serviceType)} />

                }
                {/* End Testimonials Slider portion Code Here  */}


                {/* Start Digital Transformation Portion Code Here  */}
                {content ? content[0]?.strBody && (
                    <div className='digital_transform'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12'
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            content ? content[0]?.strBody : ""
                                        ),
                                    }}
                                >


                                </div>
                            </div>
                        </div>
                    </div>
                ) : ""}
                {/* End Digital Transformation Portion Code Here  */}



                {/* Start Service Transform Portion Code Here  */}

                {
                    filteredContent &&
                    <div className='service_transform'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12'>
                                    <h2>Continue transforming with us across various domains.</h2>
                                </div>
                            </div>

                        </div>
                        <div className='container-fluid'>
                            <div className='row'>
                                {filteredContent?.map((data, i) => (
                                    <div className='col-lg-4 col-md-4  col-6' key={i}>
                                        <div className='transform_box' onClick={() => updateService(data.strTitle)}>
                                            <img src={data.strDomainImage} alt="Service Img" />
                                            <div className='overlay'>
                                                <h6>{data.strTitle}</h6>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                }


                {/* End Service Transform Portion Code Here  */}
            </>
    )
}

export default Services
