import React from 'react';
import "../Counter/counter.css";
import AOS from "aos";
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';


const Counter = () => {

    AOS.init({
        easing: "ease-in-out",
    });

    const [data, setData] = useState(null)
    useEffect(() => {
        async function fetchData() {
            try {
                const { data } = await axios.post(`${process.env.REACT_APP_API_SERVER}/clientcount`, { token: process.env.REACT_APP_API_TOKEN });
                if (data.status === 200) {
                    setData(data.content)
                }
            } catch (err) {
                console.log(err)
            }

        }
        fetchData();
    }, []);
    //    Start Scroll To Top Start Page Code 
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, left: 0 });
        });
    }, []);
    //    End Scroll To Top Start Page Code 

    return (
        <>
            {/* Start Client Counter portion Code Here  */}
            <div className='counter'>
                <div className='container'>
                    <div className='row'>
                        {
                            data?.intClientsCount &&
                            <div className='col-md-4 col-sm-4' data-aos="fade-up" data-aos-delay="100"
                                data-aos-duration="1000">
                                <h6>Clients Count</h6>
                                <h1>{data?.intClientsCount}</h1>
                            </div>
                        }

                        {
                            data?.intProductIntegrations &&
                            <div className='col-md-4 col-sm-4' data-aos="fade-up" data-aos-delay="300"
                                data-aos-duration="1500">
                                <h6>Product Integrations</h6>
                                <h1>{data?.intProductIntegrations}</h1>
                            </div>
                        }
                        {
                            data?.intOurProducts &&
                            <div className='col-md-4 col-sm-4' data-aos="fade-up" data-aos-delay="500"
                                data-aos-duration="2000">
                                <h6>Our Products</h6>
                                <h1>{data?.intOurProducts}</h1>
                            </div>
                        }

                    </div>
                </div>
            </div>
            {/* End Client Counter portion Code Here  */}
        </>
    )
}

export default Counter
