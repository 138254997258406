import React, { useEffect, useRef, useState } from 'react';
import "../Home/home.css";
import "../Home/homeresponsive.css";
import { Link, useNavigate } from 'react-router-dom';

import { Button, Tabs, Tab, Card, Collapse, Form } from 'react-bootstrap';
import TabContent from './Nascenttabs/TabContent';
// import $ from "jquery";
import HomeCarousel from './homecarousel/HomeCarousel';

import Products from './ourproducts/Products';
import ourteam from "./ourteam.jpg";
import joinus from "./joinus.jpg";
import TabSwiper from './tabswiper/TabSwiper';
import VideoBanner from '../VideoBanner/VideoBanner';
import { useDispatch, useSelector } from 'react-redux';
import { saveDataToAPI } from '../../services/actions/apiActions';
import CaseStudyCard from '../CaseStudies/CaseStudyCard';
import Error from '../../ErrorPage/Error';
import Collaborate from './Collaborate';
import Loader from "./Loader/Loader"
import Empowering from './Empowering/Empowering';
import $ from 'jquery'
const Home = () => {

  const navigate = useNavigate();
  const [caseStudiescontent, setCaseStudiesContent] = useState('');
  const [blogContent, setBlogContent] = useState('');
  const [paperContent, setPaperContent] = useState('');
  const [socialTabContent, setSocialTabContent] = useState('');

  const [latestContent, setLatestContent] = useState('');

  const dispatch = useDispatch();
  const data = useSelector((state) => state.apiData.data.data);
  const error = useSelector((state) => state.apiData.error);


  useEffect(() => {
    dispatch(saveDataToAPI("post"));
  }, [dispatch])





  useEffect(() => {
    var filteredContent = [];
    var blogFilterContent = [];
    var paperFilterContent = [];
    var socialFilterContent = [];
    if (data?.length > 0) {
      data?.filter(items => items.types?.map(element => {
        if (element.toLowerCase().split(" ").join('').includes("homecasestudy")) {
          items.redirectFrom = "home"
          filteredContent = [...filteredContent, items];
          setCaseStudiesContent(filteredContent)
        }
      }))
      data?.filter(items => items.types?.map(element => {
        if (element.toLowerCase().includes("blogs")) {
          items.type = "blog"
          blogFilterContent = [...blogFilterContent, items];

          setBlogContent(blogFilterContent);
          setLatestContent(blogFilterContent)
        }
      }))
      data.filter(items => items.types?.map(element => {
        if (element.toLowerCase().includes("papers")) {
          items.type = "paper"
          paperFilterContent = [...paperFilterContent, items];
          setPaperContent(paperFilterContent)
        }
      }))
      data.filter(items => items.types?.map(element => {
        if (element.toLowerCase().includes("social")) {
          items.type = "social"
          socialFilterContent = [...socialFilterContent, items];
          setSocialTabContent(socialFilterContent)
        }
      }))
    }
  }, [data ? data : ""]);


  // Start Latest from Nascent Tab Portion Code Here 

  const [currentTab, setCurrentTab] = React.useState("0");
  const [currentTabTitle, setCurrentTabTitle] = useState("Blogs");

  const tabChnage = (k) => {
    setCurrentTab(k);
  }

  useEffect(() => {
    if (currentTabTitle == "Blogs") {
      setLatestContent(blogContent)
    }
    else if (currentTabTitle == "Papers") {
      setLatestContent(paperContent)
    }
  }, [currentTabTitle]);

  const prevTab = () => {
    setCurrentTab((prev) => Number(prev) - 1);
  }
  const nextTab = () => {
    setCurrentTab((prev) => Number(prev) + 1);
  }
  useEffect(() => {
    if (currentTab == 0) {
      setCurrentTabTitle("Blogs");
    } else if (currentTab == 1) {
      setCurrentTabTitle("Papers");
    }  else {
      setCurrentTabTitle("Blogs");
    }
  }, [currentTab, nextTab, prevTab])

  // End Latest from Nascent Tab Portion Code Here
  //    Start Scroll To Top Start Page Code 
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0 });
    });
  }, []);

  const scrollToFooter = () => {
    document.getElementById("footer_detail").scrollIntoView({ behavior: 'smooth' });
    $(".footer_detail").addClass("scrolledToBottom");
    setTimeout(() => {
      $(".footer_detail").removeClass("scrolledToBottom");
    }, 5000)


  }

  //    End Scroll To Top Start Page Code 

  // Start Banner Text Portion Hide & Show Code Here  
  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);

  }, []);
  const videoRef = useRef();
  const [fadeOutClass, setFadeOutClass] = useState(false);
  const [videoPlay, setVideoPlay] = useState(false);

  const scrollHandler = () => {
    if (window.pageYOffset) {
      if (window.pageYOffset > 300) {
        setFadeOutClass(true);
        setVideoPlay(true)
      }
    }
    else {
      setFadeOutClass(false);
      setVideoPlay(false)
    }
  }
  // End Banner Text Portion Hide & Show Code Here  


  // Start Collaborate Form Open & Close Code Here 
  const [CollaborateOpen, setCollaborateOpen] = useState(false);
  // Start Collaborate Form Open & Close Code Here 
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    $('body').css('overflow', 'hidden')
    setTimeout(() => {
      setLoading(false);
      $('body').css('overflow', 'auto')

    }, 3000);
  }, []);


  // Start Open Position Scroll Smooth Portion Code 
  const ref = useRef(null);

  const handleClick = () => {
    setCollaborateOpen(!CollaborateOpen);
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  // End Open Position Scroll Smooth Portion Code 


  return (
    loading ? <Loader /> :
      // error ? <Error msg={error.message} /> :

      <>
        {/* Start Nascent Home Page Layout Portion Code Here  */}
        <div id='nascent_home'>

          {/* Start Banner Section Portion Code Here */}
          <div className='banner_section'>
            <div className='contact_us' onClick={scrollToFooter}>
              <Link>Contact Us</Link>
            </div>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-6 col-md-5'>
                  <div className={`${fadeOutClass == true ? "textFadeout" : ""} banner_heading`} >
                    <h3>Humane. Naturing.</h3>
                    <h1>Excellence.</h1>
                  </div>

                </div>
                <div className='col-lg-6 col-md-7'>
                  <p className='banner_text'>Technology is going to have a profound impact on shaping the planet’s future. When developed mindfully, with empathy and good intentions, it has the potential to create a meaningful change contributing to a more sustainable future. We believe in a strong collective effort to harness technology to improve the well-being of people, communities, and the planet.</p>

                  {/* <p className='banner_text'>Technology is going to have a profound impact on shaping the planet’s future. When developed mindfully, with empathy and good intentions, it has the potential to create a meaningful change contributing to a more sustainable future. We believe in a strong collective effort to harness technology to improve the well-being of people, communities, and the planet.</p> */}
                </div>
              </div>
            </div>
          </div>

          {/* <div className='banner_img' ref={videoRef}>
          <img src={bannerimg} alt="Banner Img" className='w-100' />
        </div> */}

          {/* Start Video Portion Components Code here  */}
          <VideoBanner ref={videoRef} videoPlay={videoPlay} />
          {/* End Video Portion Components Code here  */}




          <div className='banner_bottom'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-10'>
                  <p>At Nascent, we recognize the transformative power of technology in shaping our communities, environment, cities and planet. We are committed to designing solutions and providing services that help tackle challenges faced by organisations and governments in managing resources and improving lives.
                  </p>
                </div>
                <div className='col-lg-2'>
                  <div className='arrow_move'>
                    <Link to={"/thecompany"}>

                      <svg xmlns="http://www.w3.org/2000/svg" width="85.803" height="18.777" viewBox="0 0 42.803 11.777">
                        <g id="Group_3010" data-name="Group 3010" transform="translate(-1619.174 -1556.402)">
                          <path id="Path_73" data-name="Path 73" d="M-3576.721-9233.079h42.449" transform="translate(5195.895 10795.37)" fill="none" stroke="#707070" strokeWidth="0.5" />
                          <path id="Path_74" data-name="Path 74" d="M-3495.606-9264.768l5.712,5.712-5.712,5.712" transform="translate(5151.518 10821.347)" fill="none" stroke="#707070" strokeWidth="0.5" />
                        </g>
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Banner Section Portion Code Here */}

          <TabSwiper />
          {/* Start Latest From Nascent Portion Code Here  */}

          {
            blogContent && paperContent &&
            <div className={`latest_nascent ${currentTabTitle == "Social" ? "nascent_pb" : ""}`}>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-4'>
                    <div className='latest_nascent_heading'>
                      <h1>Latest</h1>
                      <h3> from Nascent.</h3>
                    </div>
                    <p className='latest_ntext first_child'>
                      Stay updated on our latest social media buzz, research papers, and insightful blogs.
                    </p>

                    <p className='latest_ntext'>Explore dynamic content for valuable industry insights, stay connected with our community, and discover solutions we're developing in the technology domain.</p>

                    <div className='nascent_slider'>
                      <Button className="btn-sm"
                        disabled={currentTab == 0}
                        onClick={() => prevTab()}
                      // onClick={handlePrevClick}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.128 36.84">
                          <path id="Path_198" data-name="Path 198" d="M0,0,18.067,18.066,0,36.133" transform="translate(18.774 36.486) rotate(180)" fill="none" stroke="#fff" strokeWidth="1" />
                        </svg>
                      </Button>
                      <p>
                        {currentTabTitle}
                      </p>
                      {/* <img src={photos[currentPhotoIndex]} alt="Current photo" /> */}
                      <Button className="btn-sm"
                        disabled={currentTab == 1}
                        onClick={() => nextTab()}
                      // onClick={handleNextClick}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.127 36.84">
                          <path id="Path_199" data-name="Path 199" d="M-3495.607-9264.767l18.067,18.065-18.067,18.067" transform="translate(3495.96 9265.12)" fill="none" stroke="#fff" strokeWidth="1" />
                        </svg>
                      </Button>
                    </div>
                  </div>
                  <div className='col-lg-8'>
                    <div className='tab_box'>


                      <div className='latest_tabs'>
                        <Tabs
                          id="controlled-tab-example"
                          activeKey={currentTab}
                          onSelect={(k) => tabChnage(k)}
                        >
                          <Tab eventKey="0" title="Blogs" className='corner_remove' >
                            <TabContent data={blogContent} />
                          </Tab>
                          <Tab eventKey="1" title="Papers">
                            <TabContent data={paperContent} />
                          </Tab>
                          {/*  <Tab eventKey="2" title="Social" >
                            <TabContent type={"social"} data={socialTabContent[0]} />
                          </Tab> */}
                        </Tabs>
                      </div>
                      <div className='download_button'>
                        {currentTabTitle != "Social" && currentTabTitle != "Blogs" &&

                          <Link to={paperContent ? paperContent[0].strFileAttach : ""} target='_blank'>
                            <svg id="Component_47_3" data-name="Component 47 – 3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.008 36.646">
                              <g id="Group_180" data-name="Group 180" transform="translate(1587.476 -1627.246) rotate(90)">
                                <path id="Path_73" data-name="Path 73" d="M-3576.721-9233.079h31.363" transform="translate(5203.966 10804.186)" fill="none" stroke="#fff" strokeWidth="2" />
                                <path id="Path_74" data-name="Path 74" d="M-3495.606-9264.768l9.869,9.869-9.869,9.869" transform="translate(5144.346 10826.005)" fill="none" stroke="#fff" strokeWidth="2" />
                              </g>
                              <path id="Path_175" data-name="Path 175" d="M-3554.186-5841.782h-33.008" transform="translate(3587.194 5877.928)" fill="none" stroke="#fff" strokeWidth="1" />
                            </svg>
                          </Link>
                        }

                        {/* <Link to={"/insights"}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.635 37.635">
                            <g id="Component_48_3" data-name="Component 48 – 3" transform="translate(0 1.5)">
                              <g id="Group_181" data-name="Group 181" transform="translate(-2254.598 68.852) rotate(-45)">
                                <path id="Path_73" data-name="Path 73" d="M-3576.721-9233.079h31.363" transform="translate(5203.966 10804.186)" fill="none" stroke="#fff" strokeWidth="2" />
                                <path id="Path_74" data-name="Path 74" d="M-3495.606-9264.768l9.869,9.869-9.869,9.869" transform="translate(5144.346 10826.005)" fill="none" stroke="#fff" strokeWidth="2" />
                              </g>
                              <path id="Path_176" data-name="Path 176" d="M-3585-5792.342v23.6h25.119" transform="translate(3586 5803.057)" fill="none" stroke="#fff" strokeWidth="1" />
                            </g>
                          </svg>
                        </Link> */}

                      </div>
                    </div>

                  </div>
                </div>
                {/* row card_top */}
                {currentTabTitle != "Social" &&
                  <div className='row card_top'>
                    {latestContent ? latestContent.map((data, i) => (
                      i > 0 && i < 3 && (
                        <div className='col-lg-4 col-sm-6' key={data.id}>
                          <div className='card'>
                            <div className='card_detail'>
                              <div className='card_text'>
                                <h6>{data.title}</h6>
                              </div>

                              <div className='card_img'>
                                <img src={data.featureImage} alt="Card img" />
                              </div>
                              <Link to={data.type == "blog" ? `/blog/${data.slug}` : `/paper/${data.slug}`} className='read_more'>Read More</Link>
                            </div>
                          </div>
                        </div>
                      )
                    )) : ""}


                    <div className='col-12'>
                      <div className='see-all'>
                        <Link to={"/insights"}> <Button className='see_btn'>See all
                        </Button></Link>

                      </div>
                    </div>
                  </div>
                }

              </div>
            </div>
          }

          {/* End Latest From Nascent Portion Code Here  */}


          {/* Start Home Page Carousel We Believe Collaborations create scope of technical interventions Portion Code Here   */}
          <HomeCarousel />
          {/* End Home Page Carousel We Believe Collaborations create scope of technical interventions Portion Code Here   */}

          {/* Start Transform, Believe, Innovate Case Studies Card Portion Code Here  */}

          {
            caseStudiescontent &&
            <div className='case_studies'>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <h2 className='c_title'>Transform. Believe. Innovate.</h2>
                  </div>
                </div>
                <div className='row'>
                  {caseStudiescontent ? caseStudiescontent.map((data, i) => (
                    i < 3 && (

                      <CaseStudyCard data={data} />
                    )
                  )) : ""}
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <div className='case_btn'>
                      <Link to={"/casestudies"}>
                        <Button className='see_more_btn'>ALL CASE STUDIES
                          <svg xmlns="http://www.w3.org/2000/svg" width="42.803" height="11.777" viewBox="0 0 42.803 11.777">
                            <g id="Group_3010" data-name="Group 3010" transform="translate(-1619.174 -1556.402)">
                              <path id="Path_73" data-name="Path 73" d="M-3576.721-9233.079h42.449" transform="translate(5195.895 10795.37)" fill="none" stroke="#707070" strokeWidth="0.5" />
                              <path id="Path_74" data-name="Path 74" d="M-3495.606-9264.768l5.712,5.712-5.712,5.712" transform="translate(5151.518 10821.347)" fill="none" stroke="#707070" strokeWidth="0.5" />
                            </g>
                          </svg>
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }



          {/* End Transform, Believe, Innovate Case Studies Card Portion Code Here  */}



          {/* Start Our Products Portion Code Here  */}
          <Products />

          {/* End Our Products Portion Code Here  */}



          {/* Start Our Tem & Join Us Portion Code Here */}
          <div className='our_team'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-6 col-sm-6'>
                  <div className='team_box'>
                    <img src={ourteam} alt="Our Team Img" />
                    <div className='team_overlay'>

                    </div>
                    <div className='team_text'>
                      <h4>Our Team</h4>
                      <p>
                        At Nascent, our culture is a vibrant tapestry woven with the threads of innovation and collaboration. We believe in the power of collective brilliance, where free-spirited minds come together to challenge conventions and shape our planet’s future.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-sm-6'>
                  <div className='team_box' onClick={() => navigate("/joinus")}>
                    <img src={joinus} alt="Join Us Img" />
                    <div className='team_overlay'>

                    </div>
                    <div className='team_text'>
                      <h4>Join Us</h4>
                      <p>
                        <h3 className='mb-2'>Passionate about innovation?</h3> Join Nascent, where your potential will be unleashed.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Our Tem & Join Us Portion Code Here */}

          {/* Start Know Your Community Portion Code Here  */}
          <Empowering />
          {/* End Know Your Community Portion Code Here  */}

          {/* Start Collaborate With Us Portion Code Here  */}
          <div className='collaborate'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-4'>
                  <h1>Collaborate </h1>
                  <h3>with us</h3>
                </div>
                <div className='col-lg-5'>
                  <div className='collaborate_text'>
                    <p>Let’s collaborate to unlock the full potential of geospatial technology and drive innovation together.</p>
                  </div>
                  <div className='collaborate_btn'>
                    <Button onClick={handleClick}
                      aria-controls="example-collapse-text"
                      aria-expanded={CollaborateOpen}>collaborate</Button>
                  </div>
                </div>
                <div className='col-lg-3'>
                  <div className='circle_warning'>
                    <p>Never stop until you are <span>'never before'!</span></p>
                  </div>
                </div>
              </div>
              <div className='row mt-5' ref={ref}>
                <Collaborate CollaborateOpen={CollaborateOpen} />
              </div>
            </div>
          </div>
          {/* End Collaborate With Us Portion Code Here  */}

        </div >
        {/* End Nascent Home Page Layout Portion Code Here  */}
      </>
  )
}


export default Home
