import React, { useEffect, useRef } from 'react';
import '../Loader/loader.css';
import anime from "animejs/lib/anime.es.js"
import AOS from "aos";

const Loader = () => {

    const animation = useRef(null);

    useEffect(() => {
        var textWrapper = document.querySelector('.ml10 .letters');
        textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

        animation.current = anime.timeline({ loop: true })
        animation.current.add({
            targets: '.ml10 .letter',
            rotateY: [-90, 0],
            duration: 1500,
            delay: (el, i) => 100 * i
        }).add({
            targets: '.ml10',
            opacity: 0,
            duration: 1000,
            easing: "easeOutExpo",
            delay: 1000
        });
        // }
    }, []);
    // Wrap every letter in a span


    AOS.init({
        easing: "ease-in-out",
    });

    return (
        <>
            {/* Start Loader Portion Code Here   */}
            <div className='theme_loader'>
                <h1 className="ml10">
                    <span className="text-wrapper">
                        <span className="letters">nascent</span>
                    </span>
                </h1>
                <p data-aos="fade-up"
                    data-aos-duration="2000" data-aos-delay="1000">Humane. Naturing. Excellence.</p>
            </div>
            {/* End Loader Portion Code Here   */}

        </>
    )
}

export default Loader
