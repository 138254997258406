import React, { useEffect, useState } from 'react';
import "../Empowering/empowering.css";
import DOMPurify from 'dompurify';
import axios from 'axios';

const Empowering = () => {
    const [data, setData]=useState(null)
    useEffect(() => {
        async function fetchData() {
            try{
                const { data } = await axios.post(`${process.env.REACT_APP_API_SERVER}/getempoweringdata`, { token: process.env.REACT_APP_API_TOKEN });
                if(data.status===200){
                    setData(data.content)
                }
            }catch(err){
                console.log(err)
            }
            
        }
        fetchData();
    }, []);
    //    Start Scroll To Top Start Page Code 
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, left: 0 });
        });
    }, []);
    //    End Scroll To Top Start Page Code 
    return (

        <div className='community_box'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='community_inner'>
                <div className='community_img c_box'>
                  <img src={data?.strImage} alt="Community Img" />
                </div>
                <div className='community_text c_box'>
                  <h2 dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            data?.strTitle
                        ),
                    }}></h2>
                  <p dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            data?.strDescription
                        ),
                    }}></p>
                  <div className='circle'>
                    <div className='eye_box'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="closeeye" viewBox="0 0 41.477 17.139">
                        <g id="Group_3029" data-name="Group 3029" transform="translate(-1294.349 -9644.499)">
                          <path id="Subtraction_13" data-name="Subtraction 13" d="M20.194,17.4a15.691,15.691,0,0,1-9.2-2.893A17.607,17.607,0,0,1,6.18,9.463L.633,11.622,0,10.379,4.968,7.194l-.056-.125L4.882,7h2.2a14.886,14.886,0,0,0,5.352,6.027,14.066,14.066,0,0,0,7.755,2.282,14.1,14.1,0,0,0,7.762-2.282A15.121,15.121,0,0,0,33.342,7h2.165c-.089.2-.159.356-.223.491L40.174,10.6l-.633,1.243L34.067,9.684a17.642,17.642,0,0,1-4.67,4.824A15.7,15.7,0,0,1,20.194,17.4Z" transform="translate(1295 9638)" fill="#fff" stroke="rgba(0,0,0,0)" strokeWidth="1" />
                          <path id="Path_296" data-name="Path 296" d="M6.47,8.243l5.372,4.616-1.778,1.767-4.583-5.4Z" transform="translate(1336.834 9664.136) rotate(180)" fill="#fff" />
                          <path id="Path_297" data-name="Path 297" d="M16.16,2.374l3.583,6.114L17.391,9.609,14.9,2.974Z" transform="translate(1339.688 9662.357) rotate(180)" fill="#fff" />
                          <path id="Path_298" data-name="Path 298" d="M48.522,8.634l-4.388,5.594-2.167-2.154,5.566-4.423Z" transform="translate(1347.885 9663.956) rotate(180)" fill="#fff" />
                          <path id="Path_301" data-name="Path 301" d="M38.2,2.265l-2.357,6.7L33.229,7.717l3.716-6.051Z" transform="translate(1345.238 9662.144) rotate(180)" fill="#fff" />
                          <path id="Path_302" data-name="Path 302" d="M27.677,0l.73,7.056-2.76.018L26.282.009Z" transform="translate(1342.941 9661.639) rotate(180)" fill="#fff" />
                        </g>
                      </svg>

                      <svg id="Group_342" className="openeye" data-name="Group 342" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.174 35.152">
                        <defs>
                          <clipPath id="clip-path">
                            <rect id="Rectangle_296" data-name="Rectangle 296" width="52.174" height="35.152" fill="#fff" />
                          </clipPath>
                        </defs>
                        <g id="Group_341" data-name="Group 341" clipPath="url(#clip-path)">
                          <path id="Path_295" data-name="Path 295" d="M22,25.331a5.9,5.9,0,0,0,5.921-5.93,5.9,5.9,0,0,0-5.929-5.921,5.9,5.9,0,0,0-5.922,5.93A5.9,5.9,0,0,0,22,25.331m0,4.531a15.623,15.623,0,0,1-9.2-2.893A17.63,17.63,0,0,1,6.66,19.405a17.63,17.63,0,0,1,6.135-7.564A15.623,15.623,0,0,1,22,8.948a15.62,15.62,0,0,1,9.2,2.893,17.62,17.62,0,0,1,6.135,7.564A17.62,17.62,0,0,1,31.2,26.969,15.62,15.62,0,0,1,22,29.862m-.006-2.091a14.027,14.027,0,0,0,7.761-2.283,14.809,14.809,0,0,0,5.385-6.083,14.824,14.824,0,0,0-5.379-6.083,14.325,14.325,0,0,0-15.518,0,15.066,15.066,0,0,0-5.42,6.083,15.078,15.078,0,0,0,5.415,6.083,14,14,0,0,0,7.756,2.283" transform="translate(3.983 1.29)" fill="#fff" />
                          <path id="Path_296" data-name="Path 296" d="M6.47,8.243l5.372,4.616-1.778,1.767-4.583-5.4Z" transform="translate(4.34 1.503)" fill="#fff" />
                          <path id="Path_297" data-name="Path 297" d="M16.16,2.374l3.583,6.114L17.391,9.609,14.9,2.974Z" transform="translate(1.487 3.281)" fill="#fff" />
                          <path id="Path_298" data-name="Path 298" d="M48.522,8.634l-4.388,5.594-2.167-2.154,5.566-4.423Z" transform="translate(-6.71 1.683)" fill="#fff" />
                          <path id="Path_299" data-name="Path 299" d="M54.48,18.481l-5.974,3.83-1.272-2.5,6.613-2.573Z" transform="translate(-8.306 -1.221)" fill="#fff" />
                          <path id="Path_300" data-name="Path 300" d="M.632,16.923l6.6,2.6L5.987,21.968,0,18.166Z" transform="translate(6 -1.125)" fill="#fff" />
                          <path id="Path_301" data-name="Path 301" d="M38.2,2.265l-2.357,6.7L33.229,7.717l3.716-6.051Z" transform="translate(-4.064 3.495)" fill="#fff" />
                          <path id="Path_302" data-name="Path 302" d="M27.677,0l.73,7.056-2.76.018L26.282.009Z" transform="translate(-1.767 4)" fill="#fff" />
                        </g>
                      </svg>
                    </div>


                    {/* <img src={closeeye} className="closeeye" alt="Close Eye" />
                    <img src={openeye} className="openeye" alt="Open Eye" />                      */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Empowering
