import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-creative';
// import required modules
import { EffectCreative } from 'swiper/modules';

// import slide05 from "../../Home/tabswiper/slide05.jpg";
import SwiperTabContent from './swipertabcontent/SwiperTabContent';
import "../tabswiper/tabswiper.css";
import "../tabswiper/tabswiperresponsive.css"
import { useDispatch, useSelector } from 'react-redux';
import { fetchPostCategoryData, saveDataToAPI } from '../../../services/actions/apiActions';
import axios from 'axios';



const TabSwiper = () => {
    const swiperRef = useRef();
    const categoryData = useSelector((state) => state.postCategory.data.content);
    const dispatch = useDispatch();
    // const data = useSelector((state) => state.apiData.data);
    const [sliderData, setSliderData] = useState(null)
    useEffect(() => {
        dispatch(fetchPostCategoryData())
        // dispatch(saveDataToAPI("servicedata"));
        const fetchData = async () => {
            try {
                const { data } = await axios.post(`${process.env.REACT_APP_API_SERVER}/servicedata`, { token: process.env.REACT_APP_API_TOKEN })
                if (data.status === 200) {
                    setSliderData(data.content)
                }
            } catch (error) {
                console.log(error)
            }


        }
        fetchData();
    }, [dispatch]);

    const [activeContent, setActiveContent] = useState(0)
    const handleTabChange = (index) => {
        swiperRef.current.slideTo(index, 1500);
        setActiveContent(index)
    }
    return (
        <>


            {/* Start Tab Swiper Portion Code Here  */}
            {sliderData &&
                <div className='tabswiper'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <h2 className='tabswiper_heading'>We bring to you, our areas of Expertise</h2>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-5 col-md-4'>
                                <div className='bring_swiper'>
                                    <Swiper

                                    //    draggable= {false}                          
                                   
                                    data-swiper-autoplay="2000"
                                    grabCursor={false}
                                    effect={'creative'}
                                    allowTouchMove={false}
                                    creativeEffect={{
                                        prev: {
                                            shadow: true,
                                            translate: [0, 0, -400],
                                        },
                                        next: {
                                            translate: ['100%', 0, 0],
                                        },
                                    }}
                                    onSwiper={(swiper) => {
                                        swiperRef.current = swiper;
                                    }}
                                    modules={[EffectCreative]}
                                    className="mySwiper"
                                    >
                                    {sliderData?.map((data, i) => (
                                        <SwiperSlide ><img src={data.strFeatureImage} alt="Slide Img" /></SwiperSlide>
                                    ))
                                    }

                                </Swiper>
                            </div>



                        </div>
                        <div className='col-lg-7 col-md-8'>
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                {
                                    categoryData?.map((data, index) => (
                                        <li className="nav-item" key={data.id} role="presentation" onClick={() => handleTabChange(index)}>
                                            <button className={`nav-link ${activeContent === index ? 'active' : ""}`} id={`${data.name.replaceAll(" ", '-')}-tab`} data-toggle="tab" data-target={`#${data.name.replaceAll(" ", '-')}`} type="button" role="tab" aria-controls="home" aria-selected="true">
                                                {data.name}
                                                <span className='circle_no'>{index + 1}</span></button>

                                        </li>
                                    ))
                                }

                            </ul>
                            <div className="tab-content" id="myTabContent">
                                {sliderData?.map((data, index) => (
                                    <div className={`tab-pane fade show ${activeContent === index ? 'active' : ""}`} id={data.strTitle.replaceAll(" ", '-')} role="tabpanel" aria-labelledby={`${data.strTitle.replaceAll(" ", '-')}-tab`} >
                                        <SwiperTabContent data={data} />
                                    </div>
                                ))}


                            </div>
                        </div>
                    </div>
                </div>
                </div >
            }

{/* End Tab Swiper Portion Code Here  */ }

        </>
    )
}
export default TabSwiper