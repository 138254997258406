import React from 'react';
import "../GetInTouch/getintouch.css";
import nascent from "../GetInTouch/nascent.png";
import { Link } from 'react-router-dom';

const GetInTouch = () => {
    return (
        <>
            {/* Start Get In Touch Portion Code Here   */}
            <div className='getintouch'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <h2>Get in Touch with us</h2>
                        </div>
                        <div className='col-lg-8 col-md-7 col-sm-6'>
                            {/* <iframe title="Get In Touch" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.217599154419!2d72.50191647515749!3d23.015781316581858!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b243ed0afbb%3A0xc22bcbb6fa7750b0!2sNascent%20Infotechnologies%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1690541872222!5m2!1sen!2sin" width="100%" height="100%" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.1660068617907!2d72.50181177548347!3d23.01767617917725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b243ed0afbb%3A0xc22bcbb6fa7750b0!2sNascent%20Infotechnologies%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1697017590244!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className='col-lg-4 col-md-5 col-sm-6'>
                            <div className='nascent_info'>
                                <div className='nascent_logo'>
                                    <img src={nascent} alt="Nascent Logo" />
                                </div>
                                <p>
                                    AF-01, Shapath-4, Opp. Karnavati Club, <br />Sarkhej - Gandhinagar Hwy, Ahmedabad, <br />Gujarat 380015
                                </p>
                                <p><Link to={"tel:+91 79 4032 1200"}>+91 79 4032 1200</Link></p>
                                <p><Link to={"mailto:contact@nascentinfo.com"}>contact@nascentinfo.com</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Get In Touch Portion Code Here   */}
        </>
    )
}

export default GetInTouch
