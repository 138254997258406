import React, { useState } from 'react'
import { Button, Collapse, Form } from 'react-bootstrap'
import swal from "sweetalert";
import 'react-phone-input-2/lib/style.css'

import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
import Loader from '../Loader/Loader';

function Collaborate({ CollaborateOpen }) {


    const {
        reset,
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors }
    } = useForm();
    const [loader, setLoader] = useState(false);

    const onSubmit = async (data) => {

        try {
            setLoader(true)
            const apiData = {
                ...data, token: process.env.REACT_APP_API_TOKEN
            }
            const response = await axios.post(`${process.env.REACT_APP_API_SERVER}/collaborate`, apiData,)
            if (response.data.status == 200) {
                reset();
                swal(
                    "Saved Successfully",
                    "Nascent Team will connect you soon",
                    "success"
                );
                setLoader(false)
            }
        } catch (error) {
            swal(
                "Server Error",
                error.message,
                "error"
            );
            setLoader(false)
        }




    }

    return (
        <>
            <div className='col-lg-8 mx-auto'>
                <Collapse in={CollaborateOpen}>

                    <Form className='collaborate_form' onSubmit={handleSubmit(onSubmit)}>
                        <h5 className='form_title'>Tell us what’s on your mind, We’ll get back to you.</h5>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 form-group'>
                                <Form.Label>Full Name<span>*</span></Form.Label>
                                <Form.Control type='text' name="fullName" placeholder='Enter Full Name'
                                    {...register("fullName", {
                                        required: "Please fill out this field.",
                                        minLength:
                                        {
                                            value: '3',
                                            message: 'Minimum 3 characters are allowed.'
                                        },
                                        maxLength:
                                        {
                                            value: '50',
                                            message: 'Maximum 50 characters are allowed.'
                                        }
                                    })} />
                                {errors.fullName && <label className="error">
                                    {errors.fullName.message}
                                </label>}
                            </div>
                            <div className='col-lg-6 col-md-6 form-group'>
                                <Form.Label>Company Name<span>*</span></Form.Label>

                                <Form.Control type='text' name="company" placeholder='Enter Company Name'
                                    {...register("company", {
                                        required: "Please fill out this field.",
                                        pattern: {
                                            value: /^[a-zA-Z ]*$/,
                                            message: "Only alphabetical is allowed in name field."
                                        },
                                        minLength:
                                        {
                                            value: '3',
                                            message: 'Minimum 3 characters are allowed.'
                                        },
                                        maxLength:
                                        {
                                            value: '50',
                                            message: 'Maximum 50 characters are allowed.'
                                        }
                                    })}
                                />

                                {errors.company && <label className="error">
                                    {errors.company.message}
                                </label>}


                            </div>
                            <div className='col-lg-6 col-md-6 form-group'>
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="text" placeholder="Enter Email address" name="email"
                                    {...register("email", {
                                        required: "Please fill out this field.",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Please enter a valid email address."
                                        },
                                        maxLength:
                                        {
                                            value: '50',
                                            message: 'Maximum 50 characters are allowed.'
                                        }
                                    })} />
                                {errors.email && <label className="error">
                                    {errors.email.message}
                                </label>}

                            </div>
                            <div className='col-lg-6 col-md-6 form-group'>
                                <Form.Label>Contact Number</Form.Label>
                                <Controller
                                    name="phone"
                                    control={control}

                                    rules={{
                                        required: "Phone number is required",
                                        minLength:
                                        {
                                            value: '15',
                                            message: 'Please enter a valid phone number.'
                                        },
                                    }}
                                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                        <PhoneInput
                                            enableSearch
                                            onBlur={onBlur}
                                            country={'in'}

                                            countryCodeEditable={false}
                                            onChange={(value, data, event, formattedValue) => {
                                                setValue("phone", formattedValue, {
                                                    shouldValidate: true,
                                                    shouldDirty: true
                                                });
                                            }}

                                        />
                                    )}
                                />
                                {errors.phone && <label className="error">
                                    {errors.phone.message}
                                </label>}

                            </div>
                            <div className='col-12 form-group'>
                                <Form.Label style={{ opacity: "0" }}>Email address</Form.Label>
                                <textarea placeholder='Brief about the purpose of collaboration' rows={5}
                                    name='message'
                                    {...register("message", {
                                        required: "Please fill out this field.",
                                        minLength:
                                        {
                                            value: '5',
                                            message: 'Minimum 5 characters are allowed.'
                                        },
                                        maxLength:
                                        {
                                            value: '500',
                                            message: 'Maximum 500 characters are allowed.'
                                        }
                                    }
                                    )}
                                ></textarea>
                                {errors.message && <label className="error">
                                    {errors.message.message}
                                </label>}
                            </div>
                            <div className='col-12 text-center form-group'>
                                <Button variant="warning" type="submit">
                                    SEND MESSAGE
                                </Button>
                            </div>
                        </div>

                    </Form>
                </Collapse>

            </div>
            {loader && <Loader />}
        </>
    )
}

export default Collaborate