import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import "./footer.css";
import "./footerresponsive.css";
import { Form, Button } from 'react-bootstrap';
import nascent from "./nascent.png";
import scrolltotops from "./scrolltotop.png";
import NewsLetter from './NewsLetter';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPostCategoryData } from '../../services/actions/apiActions';


const Footer = () => {

  //Start Footer Logo Click To Top Start Page Code
  const scrollToTop = () => {
    window.scrollTo('top', {
      duration: 800,
      smooth: 'easeInOutQuart',
    });
  };

  //End Footer Logo Click To Top Start Page Code


  // Start Top To Scroll Button Hide & Show Functionality Code Here 
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsScrolled(scrollTop > 300); // Adjust the value as needed
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  // End Top To Scroll Button Hide & Show Functionality Code Here 


  const categoryData = useSelector((state) => state.postCategory.data.content);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPostCategoryData())

  }, [dispatch]);

  const navigate = useNavigate();
  const naviagateToService = (type) => {
    navigate('/services', { state: type });


  }
  const { state, pathname } = useLocation();
  const currentActiveNav = pathname?.replace('/', '');


  return (
    <>
      {/* Start Footer Portion Code Here  */}
      <footer>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='footer_link'>
                <ul>
                  <li className={`${currentActiveNav === '' ? 'active' : ''}`}>
                    <Link to="/">Home</Link>
                  </li>
                  <li className={`${currentActiveNav === 'thecompany' ? 'active' : ''}`}>
                    <Link to="/thecompany">The Company</Link>
                  </li>
                  {/* <li>
                    <Link to="/services">Services</Link>
                  </li> */}
                  <li className={`${currentActiveNav == 'insights' ? 'active' : ''}`}>
                    <Link to="/insights">Insights</Link>
                  </li>
                  <li className={`${currentActiveNav == 'casestudies' ? 'active' : ''}`}>
                    <Link to="/casestudies">Case Studies</Link>
                  </li>
                  {/* <li className={`${currentActiveNav == 'products' ? 'active' : ''}`}>
                    <Link>Products</Link>
                  </li> */}
                  <li className={`${currentActiveNav == 'joinus' ? 'active' : ''}`}>
                    <Link to="/joinus">Join Us</Link>
                  </li>
                  {/*  <li className={`${currentActiveNav == 'termscondition' ? 'active' : ''}`}>
                    <Link to="/termscondition">Terms & Conditions</Link>
                  </li> */}
                  <li className={`${currentActiveNav == 'privacypolicy' ? 'active' : ''}`}>
                    <Link to="/privacypolicy">Privacy Policy</Link>
                  </li>
                  {/* <li>
                    <Link>Contact Us</Link>
                  </li> */}
                </ul>
                <ul>
                  {
                    categoryData &&
                    <>
                      <h5>Services</h5>
                      {categoryData?.map((data, index) => (
                        <li className={`${state == data.name ? 'active' : ""}`} key={index} onClick={() => naviagateToService(data.name)}>
                          <Link>{data.name}</Link>
                        </li>
                      ))}
                    </>
                  }


                </ul>
                <ul>
                  {/* <li>
                    <Link>Feedback</Link>
                  </li> */}
                  {/* <li>
                    <Link>Help</Link>
                  </li> */}
                  {/* <li>
                    <Link to="/termscondition">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacypolicy">Privacy Policy</Link>
                  </li> */}
                  {/* <li>
                    <Link>Downloads</Link>
                  </li> */}

                </ul>
              </div>

              <div className='footer_detail' id="footer_detail">
                <div className='footer_data'>
                  <span className='footer_logo'>
                    <img src={nascent} alt="Nascent Logo" />
                    {/* <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 170.301 30.068">
                      <defs>
                        <clipPath id="clip-path">
                          <rect id="Rectangle_3" data-name="Rectangle 3" width="170.301" height="30.069" fill="#fff" />
                        </clipPath>
                      </defs>
                      <g id="Group_57" data-name="Group 57" opacity="0.201">
                        <g id="Group_1" data-name="Group 1" transform="translate(0 0)" clipPath="url(#clip-path)">
                          <path id="Path_1" data-name="Path 1" d="M23.506,29.653h-7.68L11.5,17.338q-.557-1.539-.988-3.042c-.289-1-.575-2.116-.86-3.349q-.387,3.221-1.03,6.565L6.349,29.653H0L5.492.695h7.723l4.157,11.326q.6,1.632,1.112,3.39c.35,1.175.675,2.418.988,3.736q.391-3.479,1.117-7.423L22.691.695h6.347Z" transform="translate(0 -0.141)" fill="#fff" />
                          <path id="Path_2" data-name="Path 2" d="M49.706,13.224c-.143-1.119-.254-1.992-.324-2.619a20.373,20.373,0,0,1-.105-2.189c-.487,1.086-.869,1.935-1.138,2.532s-.692,1.472-1.265,2.617L44,19.01h6.563Zm2.272,16.43-.729-5.363H41.427l-2.746,5.363H31.946L47.089.695h7.464l4.414,28.958Z" transform="translate(-6.483 -0.141)" fill="#fff" />
                          <path id="Path_3" data-name="Path 3" d="M86.752,6.263a9.655,9.655,0,0,0-2-.513,13.686,13.686,0,0,0-2.166-.172,5.324,5.324,0,0,0-3.364.9,2.737,2.737,0,0,0-1.138,2.229q0,1.506,4.011,4.27T86.107,19.6a9.474,9.474,0,0,1-3.493,7.442,13.2,13.2,0,0,1-8.943,3.024,25.415,25.415,0,0,1-5.576-.556l1.16-6.224a13.769,13.769,0,0,0,2.421.6,15.739,15.739,0,0,0,2.637.257,5.734,5.734,0,0,0,3.453-.878A2.909,2.909,0,0,0,78.948,20.8q0-1.459-3.97-4.138T71.01,9.951a9.307,9.307,0,0,1,3.067-7.1Q77.141,0,82.892,0a31.953,31.953,0,0,1,5.014.429Z" transform="translate(-13.82 0)" fill="#fff" />
                          <path id="Path_4" data-name="Path 4" d="M115.732,7.261a13.846,13.846,0,0,0-4.716-.769,7.573,7.573,0,0,0-7.036,3.763,15.793,15.793,0,0,0-2.231,8.214,5.826,5.826,0,0,0,1.265,4.046q1.264,1.39,4.229,1.388a16.147,16.147,0,0,0,2.849-.239,13.665,13.665,0,0,0,2.677-.748l-.511,6.134a28.407,28.407,0,0,1-3.395.687,25.122,25.122,0,0,1-3.6.259q-6.179,0-8.58-3a11.223,11.223,0,0,1-2.4-7.24A21.078,21.078,0,0,1,98.852,6.223Q103.419.484,111.572.481A22.357,22.357,0,0,1,114.723.7a21.287,21.287,0,0,1,2.768.556Z" transform="translate(-19.135 -0.097)" fill="#fff" />
                          <path id="Path_5" data-name="Path 5" d="M142.375,6.32h-9.438l-1.071,5.748h8.964l-1.071,5.444h-8.923L129.595,23.9h9.434l-1.074,5.75H122L127.489.7h15.96Z" transform="translate(-24.76 -0.142)" fill="#fff" />
                          <path id="Path_6" data-name="Path 6" d="M166.238,14.492,161.328.7h-7.723l-5.492,28.95h6.35l2.273-12.139c.431-2.229.768-4.414,1.031-6.563.433,1.438,5.81,17.784,5.81,17.784s2.705-14.087,2.66-14.24" transform="translate(-30.06 -0.142)" fill="#fff" />
                          <path id="Path_7" data-name="Path 7" d="M207.2,6.405h-6.777l-4.461,23.249H189.44l4.416-23.249h-6.782L188.149.7h20.12Z" transform="translate(-37.967 -0.142)" fill="#fff" />
                          <path id="Path_8" data-name="Path 8" d="M175.435.7,170.15,29.655h5.944L181.629.7Z" transform="translate(-34.532 -0.142)" fill="#fff" />
                        </g>
                      </g>
                    </svg> */}

                  </span>
                  <span><p>AF-01, Shapath-4, Opp. Karnavati Club, Sarkhej - Gandhinagar Hwy, Ahmedabad, Gujarat 380015</p></span>
                </div>
                <div className='email_mobile' id="footer_detail">
                  <a href='mailto:contact@nascentinfo.com'>contact@nascentinfo.com</a><span className='dot'></span> <a href='tel:+91 79403 21200'>+91 79 4032 1200</a>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              {/* {showTopBtn && ( */}
              <div
                className={`scroll_top ${isScrolled ? 'show' : ''}`}
                onClick={scrollToTop}
              >
                <img src={scrolltotops} alt="Scroll To Top Img" />
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 50 50">
                  <g id="Group_267" data-name="Group 267" transform="translate(-1741 -8703)">
                    <g id="Ellipse_49" data-name="Ellipse 49" transform="translate(1741 8703)" fill="none" stroke="#fff" strokeWidth="1">
                      <circle cx="25" cy="25" r="25" stroke="none" />
                      <circle cx="25" cy="25" r="24.5" fill="none" />
                    </g>
                    <path id="Path_177" data-name="Path 177" d="M-3495.606-9264.768l6.631,6.631-6.631,6.631" transform="translate(11024.137 5235.709) rotate(-90)" fill="none" stroke="#fff" strokeWidth="2" />
                  </g>
                </svg> */}
              </div>
              {/* )} */}
              <div className='footer_media'>
                <NewsLetter />
                <div className='followus'>
                  <Form.Label>Follow Us</Form.Label>
                  <ul>
                    <li>
                      <Link to={"https://www.youtube.com/user/Nascentinfotech"} target="_blank"><i className="fab fa-youtube"></i></Link>
                    </li>
                    <li>
                      <Link to={"https://www.linkedin.com/company/nascent-info-technologies/"} target='_blank'><i className="fab fa-linkedin"></i></Link>
                    </li>
                    <li>
                      <Link to={"https://twitter.com/nascentinfo"} target="_blank">
                        {/* <i className="fab fa-twitter"></i> */}
                        <i className="fa-brands fa-x-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      {/* <Link><i className="fab fa-instagram-square"></i></Link> */}
                      <Link to={"https://www.instagram.com/nascent_info"} target="_blank">
                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
                          <g id="invisible_box" data-name="invisible box">
                            <rect id="Rectangle_47" data-name="Rectangle 47" width="36" height="36" fill="none" />
                            <rect id="Rectangle_48" data-name="Rectangle 48" width="36" height="36" fill="none" />
                          </g>
                          <g id="icons_Q2" data-name="icons Q2" transform="translate(5.019 5.014)">
                            <path id="Subtraction_8" data-name="Subtraction 8" d="M4362.19-1661.252H4351.6a9.329,9.329,0,0,1-3.164-.645,5.515,5.515,0,0,1-2.26-1.486,5.5,5.5,0,0,1-1.485-2.259,9.33,9.33,0,0,1-.646-3.164c-.063-1.355-.063-1.8-.063-5.295s0-3.938.063-5.295a9.33,9.33,0,0,1,.646-3.163,5.5,5.5,0,0,1,1.485-2.26,6.517,6.517,0,0,1,2.26-1.614,11.541,11.541,0,0,1,3.164-.581h5.294l5.295.065a11.557,11.557,0,0,1,3.163.581,6.42,6.42,0,0,1,2.26,1.55,6.406,6.406,0,0,1,1.549,2.26,11.585,11.585,0,0,1,.582,3.163c.064,1.357.064,1.8.064,5.295s0,3.939-.064,5.295a11.588,11.588,0,0,1-.582,3.164,6.487,6.487,0,0,1-1.549,2.259,5.5,5.5,0,0,1-2.26,1.486A9.333,9.333,0,0,1,4362.19-1661.252Zm-5.295-19.5a6.658,6.658,0,0,0-6.65,6.651,6.657,6.657,0,0,0,6.65,6.65,6.658,6.658,0,0,0,6.65-6.65A6.658,6.658,0,0,0,4356.9-1680.752Zm6.908-1.807a1.552,1.552,0,0,0-1.55,1.55,1.552,1.552,0,0,0,1.55,1.55,1.552,1.552,0,0,0,1.551-1.55A1.552,1.552,0,0,0,4363.8-1682.559Zm-6.908,12.784a4.331,4.331,0,0,1-4.326-4.325,4.331,4.331,0,0,1,4.326-4.326,4.331,4.331,0,0,1,4.327,4.326A4.331,4.331,0,0,1,4356.9-1669.775Z" transform="translate(-4343.981 1687.014)" fill="#e8e8e8" />
                          </g>
                        </svg>
                      </Link>
                    </li>
                    <li>
                      <Link to={"https://www.facebook.com/nascentinfo"} target="_blank"><i className="fab fa-facebook-square"></i></Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='copyright'>
                <p>© Copyright  NASCENT Info-technologies, {new Date().getFullYear()}</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* End Footer Portion Code Here  */}
    </>
  )
}

export default Footer
