import React from 'react'
import "./error.css"
function Error({ msg }) {
    return (
        <div className='error-page'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        {/* <h1>NASCENT Info Technologies Pvt. Ltd.</h1> */}
                        <div className='msg'>
                            <h2>{msg}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Error