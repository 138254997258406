import React, { useEffect, useState } from 'react'
import InsightsHeading from '../../../InsightsHeading/InsightsHeading';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from 'react-bootstrap';
import "../PapersDetail/papersdetail.css";
import "../PapersDetail/papersdetailresponsive.css";
import { useDispatch, useSelector } from 'react-redux';
import { saveDataToAPI } from "../../../../../services/actions/apiActions";
import DOMPurify from 'dompurify';
import Error from '../../../../../ErrorPage/Error';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import PostLike from '../../PostLike';
const PapersDetail = () => {

    const location = useLocation();
    const [slug, setSlug] = useState(location.pathname.replace("/paper/", ""))
    const dispatch = useDispatch();
    const data = useSelector((state) => state.apiData.data.data);
    const error = useSelector((state) => state.apiData.error);

    useEffect(() => {
        dispatch(saveDataToAPI("post"));
    }, [dispatch, location.pathname])
    let paperData;
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, left: 0 });
        });
    }, [location.pathname]);
    if (data?.length > 0) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].slug == slug) {
                data[i].types.map(element => {
                    if (element.toLowerCase().includes("papers")) {
                        paperData = data[i]
                        // var filteredData = data[i];
                        // filteredData.body = filteredData.body.replace("<p>", "");
                        // filteredData.body = filteredData.body.replace("</p>", "")
                        // console.log(filteredData.body)

                    }
                });
            }
        }
    }
    var filteredContent = [];
    if (data?.length > 0) {
        for (let i = 0; i < data.length; i++) {
            data[i].types.map(element => {
                if (element.toLowerCase().includes("papers") && data[i].title != paperData?.title) {
                    filteredContent = [...filteredContent, data[i]];

                }
            });
        }
    }
    const updateSlug = (slug) => {
        navigate(`/paper/${slug}`);
        setSlug(slug)
        dispatch(saveDataToAPI("post"));
    }

    //  Start Navigate To Back To Insights Page 
    let navigate = useNavigate();
    const BackInsightsClick = () => {
        navigate('/insights', { state: "PAPERS" });
    }
    //  End Navigate To Back To Insights Page 

    //    Start Scroll To Top Start Page Code 
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, left: 0 });
        });
    }, []);

    //    End Scroll To Top Start Page Code 

    // Start Like and Unliked Portion Code Here
    const [toggleLike, setToggleLike] = useState("UnLike");
    const toggleLikes = () => {
        if (toggleLike === "UnLike") {
            setToggleLike("Like");

            return;
        } else setToggleLike("UnLike");
    };
    // End Like and Unliked Portion Code Here



    // Start Papers Detail Owl Carousel Responsive Code
    const paperdetailslider = {
        loop: true,
        margin: 0,
        nav: true,
        // smartSpeed:3000,
        autoplaySpeed: 6000,
        // autoplayspeed: 2500,
        // autoplaytimeout: 1500,
        // slidetransition: "linear",
        autoplay: true,
        // autoplayTimeout: 6000,
        // autoplayHoverPause: true,

        responsive: {
            0: {
                items: 1,
                // stagePadding: 10,
                autoplay: false,
            },
            768: {
                items: 2,
                // touchDrag:false,
                // mouseDrag:false,
                // stagePadding: 100,
            },

        },

    };
    // End Papers Detail Owl Carousel Responsive Code

    return (
        error ? <Error msg={error.message} /> :

            <>



                {/* Start Insights Heading Portion Code Here  */}
                <InsightsHeading title={"PAPERS"} />
                {/* End Insights Heading Portion Code Here  */}
                {/* Start Papers Detail Portion Code Here  */}
                <div className='papers_detail'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <Button className='text-dark' onClick={BackInsightsClick}>Back</Button>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-10 col-11 mx-auto mt-4'>
                                <div className='papers_card_box'>
                                    <div className='paper_detail_layer'>
                                        <div className='paper_img'>
                                            <img src={paperData?.Image} alt="Papers Detail Banner" />
                                            <div className='paper_text'>
                                                <p>{paperData?.date}</p>
                                                <h6>{paperData?.title}</h6>
                                            </div>
                                        </div>
                                        <iframe

                                            title="PDF Viewer"
                                            src={paperData?.strFileAttach}
                                            width="100%"
                                            height="600"
                                            frameBorder="0"
                                            allowFullScreen
                                        />
                                        {/* <div className='papers_descp'
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(
                                                    paperData?.body
                                                ),
                                            }}

                                        >
                                        </div> */}


                                        {/* Start Blog Like Portion Code Here  */}

                                        {/* <PostLike type={"paper"} data={paperData} /> */}
                                        {/* End Blog Like Portion Code Here  */}
                                    </div>
                                </div>
                            </div>
                            {filteredContent &&
                                <div className='col-lg-8 col-md-10 col-11 mx-auto detail_card_m'>
                                    {filteredContent?.length > 2 ?
                                        <div className='row'>
                                            <h6 className='rotate_heading'>SIMILAR PAPERS</h6>

                                            <div className='col-12 '>
                                                <OwlCarousel className="owl-theme" {...paperdetailslider} autoplaySpeed={2500}
                                                    autoplayTimeout={3000}
                                                // slidetransition={linear}
                                                >
                                                    {filteredContent.map((data, i) => (
                                                        <div key={i} className="item" onClick={() => updateSlug(data.slug)} >
                                                            <div className='paper_detl_card'>
                                                                <div className='papers_overlay'>
                                                                    <div className='papers_text'>
                                                                        <h6>{data.title}</h6>
                                                                    </div>
                                                                    <div className='papers_img' >
                                                                        <img src={data.featureImage} alt="Papers Card Img" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </OwlCarousel>
                                            </div>
                                        </div>
                                        :
                                        <div className='row'>
                                            <h6 className='rotate_heading'>SIMILAR PAPERS</h6>
                                            {filteredContent?.map((data, i) => (
                                                <>
                                                    <div className='col-lg-6 mb-lg-0 mb-5'>
                                                        <div div className="item" key={i} onClick={() => updateSlug(data.slug)} >
                                                            <div className='paper_detl_card'>
                                                                <div className='papers_overlay'>
                                                                    <div className='papers_text'>
                                                                        <h6>{data.title}</h6>
                                                                    </div>
                                                                    <div className='papers_img' >
                                                                        <img src={data.featureImage} alt="Papers Card Img" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div >

                                                </>
                                            ))}

                                        </div>
                                    }
                                </div>
                            }



                        </div>

                    </div >
                </div >
                {/* End Papers Detail Portion Code Here  */}
            </>
    )
}


export default PapersDetail
