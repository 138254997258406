import React, { useEffect, useState } from 'react';

import "../PapersContent/paperscontent.css";
import { useNavigate } from "react-router-dom";

const PapersContent = ({ data }) => {

    const [content, setContent] = useState('');


    useEffect(() => {
        if (data?.length > 0) {
            var filteredContent = [];

            for (let i = 0; i < data.length; i++) {
                data[i].types?.map(element => {
                    if (element.toLowerCase().includes("papers")) {
                        filteredContent = [...filteredContent, data[i]];
                        setContent(filteredContent)
                    }
                });
            }
        }
    }, [data]);

    //  Start Navigate To Blog Detail Page 
    let navigate = useNavigate();
    const papersDetailClick = (slug) => {
        navigate(`/paper/${slug}`);
    }
    // End Navigate To Blog Detail Page 
    return (
        <>
            {/* Start Papers Content Portion Code Here   */}
            <div className='papers_content'>
                <div className='container-fluid px-0'>
                    <div className='row'>
                        {content ? content.map((data, i) => (
                            <div className='col-md-6 col-11' key={data.id} onClick={() => papersDetailClick(data.slug)}>
                                <div className='papers_card' >
                                    <div className='papers_overlay'>
                                        <div className='papers_text'>
                                            <h6>{data.title}</h6>
                                            <p>{data.description} </p>
                                        </div>
                                        <div className='papers_img'>
                                            <img src={data.featureImage} alt="Papers Card Img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : ""}

                    </div>

                </div>
            </div>
            {/* End Papers Content Portion Code Here   */}
        </>
    )
}

export default PapersContent
