import axios from 'axios';


const ApiService = {

    saveData: async (endpoint, data) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_SERVER}/${endpoint}`, data);
            return response;
        } catch (error) {
            throw error;
        }
    },

};

export default ApiService;