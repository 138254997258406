import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-phone-input-2/lib/style.css'
import "./assets/fontawesome-free-5.15.4-web/css/all.min.css";
import "./App.css";
import "./assets/css/fontfamily.css";
import "./assets/css/unpkg.com_aos@2.3.1_dist_aos.css";
import "./assets/js/custom.js"
import "./developer.css"
import Home from './components/Home/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import TheCompany from './components/TheCompany/TheCompany';
import Services from './components/Services/Services';
import Insights from './components/Insights/Insights';
import BlogDetail from './components/Insights/InsightsTabContent/BlogContent/BlogDetail/BlogDetail';
import PapersDetail from './components/Insights/InsightsTabContent/PapersContent/PapersDetail/PapersDetail';
import JoinUs from "./components/JoinUs/JoinUs";
import CaseStudiesDetail from './components/CaseStudies/CaseStudiesDetail/CaseStudiesDetail';
import CaseStudies from './components/CaseStudies/CaseStudies';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import TermsCondition from "./components/TermsCondition/TermsCondition";
import Layout from "./layouts/Layout"
import Layers from "./components/Products/Layers/Layers"
import Ncollector from './components/Products/NCollector/Ncollector';
import PageNotFound from './components/PageNotFound/PageNotFound';


function App() {
  return (
    <div className="App">
      <BrowserRouter basename={'/'}>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/thecompany" element={<TheCompany />} />
            <Route path="/services" element={<Services />} />
            <Route path="/insights" element={<Insights />} />
            <Route path="/blog/:slug" element={<BlogDetail />} />
            <Route path="/paper/:slug" element={<PapersDetail />} />
            <Route path="/joinus" element={<JoinUs />} />
            <Route path="/casestudies" element={<CaseStudies />} />
            <Route path="/casestudy/:slug" element={<CaseStudiesDetail />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/termscondition" element={<TermsCondition />} />
            <Route path="/layers" element={<Layers />} />

          </Route>
          <Route path="*" element={<PageNotFound />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

