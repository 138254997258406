import { combineReducers } from "redux";
import { FETCH_DATA2_FAILURE, FETCH_DATA2_REQUEST, FETCH_DATA2_SUCCESS, SAVE_DATA_FAILURE, SAVE_DATA_REQUEST, SAVE_DATA_SUCCESS } from "../constant";

const initialState = {
    data: [],
    isLoading: true,
    error: null,
};





const postCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DATA2_REQUEST:
            return { ...state, isLoading: true };
        case FETCH_DATA2_SUCCESS:
            return { ...state, isLoading: false, data: action.payload };
        case FETCH_DATA2_FAILURE:
            return { ...state, isLoading: false, error: action.payload };
        default:
            return state;
    }
};

const saveDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_DATA_REQUEST:
            return { ...state, isLoading: true };
        case SAVE_DATA_SUCCESS:
            return { ...state, isLoading: false, data: action.payload };
        case SAVE_DATA_FAILURE:
            return { ...state, isLoading: false, error: action.payload };
        default:
            return state;
    }
};



const rootReducer = combineReducers({
    postCategory: postCategoryReducer,
    apiData: saveDataReducer

});

export default rootReducer;