import React from 'react';
import "../InsightsHeading/insightsheading.css";
// import { Button } from 'react-bootstrap';
// import { useNavigate } from "react-router-dom";

const InsightsHeading = ({title}) => {

    return (
        <>
            {/* Start Insights Heading Portion Code Here  */}
            <div className='insight_heading'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className='heading'>Insights</h2>
                            <p className='sub_heading'>{title}</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Insights Heading Portion Code Here  */}
        </>
    )
}

export default InsightsHeading
