import React, { useEffect, useState } from 'react';
import "../InsightsTabContent/insightstabcontent.css";
import "../InsightsTabContent/insightstabcontentresponsive.css"
import BlogContent from './BlogContent/BlogContent';
import PapersContent from './PapersContent/PapersContent';
import SocialContent from './SocialContent/SocialContent';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPostData, saveDataToAPI } from '../../../services/actions/apiActions';
import { useLocation } from 'react-router-dom';
import Error from '../../../ErrorPage/Error';

const InsightsTabContent = () => {
    const { state } = useLocation();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.apiData.data.data);
    const error = useSelector((state) => state.apiData.error);
    const loading = useSelector((state) => state.post);

    useEffect(() => {
        dispatch(saveDataToAPI("post"));
    }, [dispatch])
    // Start Tab Active Title Name Portion Code 
    const [headingTitle, setHeadingTitle] = useState(state != null ? state : "BLOGS");

    const changeTab = (tabName) => {
        setHeadingTitle(tabName);
    }
    // End Tab Active Title Name Portion Code 
    return (
        error ? <Error msg={error.message} /> :
            <>
                {/* Start Insights Heading Portion Code Here  */}
                <div className='insight_heading' >
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <h2 className='heading'>Insights</h2>
                                <p className='sub_heading'>{headingTitle}</p>
                            </div>
                        </div>
                    </div>
                </div >
                {/* End Insights Heading Portion Code Here  */}
                {/* Start Insights Tab Content portion Code here  */}

                <div className='insights_tab'>
                    <div className='container'>

                        <div className="row">
                            <div className="col-lg-4">
                                <div className="nav nav-pills" id="v-pills-tab" role="tablist">
                                    <button className={`nav-link ${headingTitle == "BLOGS" ? 'active' : ""}`} id="v-pills-home-tab" data-toggle="pill" data-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected={`${headingTitle == "BLOGS" ? 'true' : 'false'}`} onClick={() => changeTab("BLOGS")}>Blogs</button>
                                    <button className={`nav-link ${headingTitle == "PAPERS" ? 'active' : ""}`} id="v-pills-profile-tab" data-toggle="pill" data-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected={`${headingTitle == "PAPERS" ? 'true' : 'false'}`} onClick={() => changeTab("PAPERS")}>Papers</button>
                                    {/* <button className={`nav-link ${headingTitle == "SOCIAL" ? 'active' : ""}`} id="v-pills-messages-tab" data-toggle="pill" data-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected={`${headingTitle == "SOCIAL" ? 'true' : 'false'}`} onClick={() => changeTab("SOCIAL")}>Social</button> */}

                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="tab-content" id="v-pills-tabContent">
                                    <div className={`tab-pane fade show ${headingTitle == "BLOGS" ? 'active' : ""}`} id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                        <BlogContent data={data ? data : ""} />
                                    </div>
                                    <div className={`tab-pane fade show ${headingTitle == "PAPERS" ? 'active' : ""}`} id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                        <PapersContent data={data ? data : ""} />
                                    </div>
                                   {/*  <div className={`tab-pane fade show ${headingTitle == "SOCIAL" ? 'active' : ""}`} id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                        <SocialContent />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* End Insights Tab Content portion Code here  */}
            </>
    )
}


export default InsightsTabContent
