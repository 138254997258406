import axios from "axios";
import { useState } from "react";

function useApiSave() {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [resData, setResData] = useState(null);

    const saveData = async (endpoint, payload) => {
        // console.log(endpoint, payload)
        setIsLoading(true);
        setError(null);
        try {
            const apiData = {
                ...payload, token: process.env.REACT_APP_API_TOKEN
            }
            const response = await axios.post(`${process.env.REACT_APP_API_SERVER}/${endpoint}`, apiData, { headers: { "Content-Type": "multipart/form-data" } });
            setResData(response.data);
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        error,
        resData,
        saveData,
    };
}

export default useApiSave;