import React from 'react'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

function CaseStudyCard({ data, classStyle }) {
    let navigate = useNavigate();
    const navigateToCaseStudiesDetail = (slug) => {
        navigate(`/casestudy/${slug}`);

    }
    return (

        <div className={`col-lg-4 col-md-6 ${classStyle}`}
            key={data.id}

        >
            <Card >
                <div className='case_overlay'
                    onClick={() => navigateToCaseStudiesDetail(data.slug)}>
                    <Card.Img variant="top" src={data.featureImage} alt='Case img' />
                    <div className='c_overlay'>
                    </div>
                    <h5>{data.title}</h5>
                </div>

                <Card.Body>
                    <div className='card_tag'>
                        {data.tags?.map((tag) => (
                            <span>{tag}</span>
                        ))}
                    </div>
                    {data.redirectFrom == "home" &&
                        <Card.Text>
                            {data.description}
                        </Card.Text>
                    }
                </Card.Body>
            </Card>
        </div>

    )
}

export default CaseStudyCard