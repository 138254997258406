import React, { useState, useEffect } from 'react';
import "../BlogDetail/blogdetail.css";
import { Button } from 'react-bootstrap';
import InsightsHeading from '../../../InsightsHeading/InsightsHeading';
import { useLocation, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../BlogDetail/blogdetailresponsive.css";
import { saveDataToAPI } from "../../../../../services/actions/apiActions";
import DOMPurify from 'dompurify';
import Error from '../../../../../ErrorPage/Error';
import { useDispatch, useSelector } from 'react-redux';
import PostLike from '../../PostLike';

const BlogDetail = () => {
    const location = useLocation();
    const [slug, setSlug] = useState(location.pathname.replace("/blog/", ""));
    const dispatch = useDispatch();
    const data = useSelector((state) => state.apiData.data.data);
    const error = useSelector((state) => state.apiData.error);


    useEffect(() => {
        dispatch(saveDataToAPI("post"));
    }, [dispatch, location.pathname])

    let blogData;
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, left: 0 });
        });
    }, [location.pathname]);
    let readTimeMinutes;

    if (data?.length > 0) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].slug == slug) {
                data[i].types.map(element => {
                    if (element.toLowerCase().includes("blogs")) {
                        blogData = data[i];
                        const wordsPerMinute = 100;
                        const contents = data[i].body;
                        const wordCount = contents.split(/\s+/).length;
                        readTimeMinutes = Math.ceil(wordCount / wordsPerMinute);


                    }
                });
            }
        }
    }
    var filteredContent = [];
    if (data?.length > 0) {
        for (let i = 0; i < data.length; i++) {
            data[i].types.map(element => {
                if (element.toLowerCase().includes("blogs")) {
                    filteredContent = [...filteredContent, data[i]];
                }
            });
            filteredContent = filteredContent.filter(items => items.title != blogData.title)

        }
        for (let i = 0; i < filteredContent.length; i++) {
            const wordsPerMinute = 100;
            const contents = filteredContent[i].body;
            const wordCount = contents.split(/\s+/).length;
            const readTimeMinutes = Math.ceil(wordCount / wordsPerMinute);
            filteredContent[i].timeRead = readTimeMinutes
        }
    }
    const updateSlug = (slug) => {
        navigate(`/blog/${slug}`);
        dispatch(saveDataToAPI("post"));
        setSlug(slug)

    }

    //    End Scroll To Top Start Page Code 

    // Start Blog Detail Owl Carousel Responsive Code
    const blogdetailslider = {
        loop: true,
        margin: 0,
        nav: true,
        // smartSpeed:3000,
        // autoplaySpeed: 6000,
        // autoplayspeed: 2500,
        // autoplaytimeout: 1500,
        // slidetransition: "linear",
        autoplay: true,
        // autoplayTimeout: 6000,
        // autoplayHoverPause: true,

        responsive: {
            0: {
                items: 1,
                stagePadding: 10,
                autoplay: false,
            },
            768: {
                items: 1,
                // stagePadding: 100,
            },

        },
    };
    // End Blog Detail Owl Carousel Responsive Code


    //  Start Navigate To Back To Insights Page 
    const navigate = useNavigate();
    const BackInsightsClick = () => {
        navigate('/insights', { state: "BLOGS" });
    }
    //  End Navigate To Back To Insights Page 


    return (
        error ? <Error msg={error.message} /> :

            <>
                {/* Start Insights Heading Portion Code Here  */}
                <InsightsHeading title="BLOGS" />
                {/* End Insights Heading Portion Code Here  */}
                {/* Start Blog Detail Portion Code Here  */}
                <div className='blog_detail'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <Button className='text-dark' onClick={BackInsightsClick}>Back</Button>
                            </div>
                        </div>
                    </div>

                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-8 col-md-10 mx-auto'>
                                <div className='blog_detail_date'>
                                    <span>{blogData?.date}</span><span className='dot'></span><span>{readTimeMinutes ? readTimeMinutes : ''} Minutes Read</span>
                                </div>
                                <h5>{blogData?.title}</h5>
                            </div>
                        </div>
                        <div className='row'
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    blogData?.body
                                ),
                            }}
                        >
                        </div>
                        <div className='row'>

                            <div className='col-lg-8 col-md-10 mx-auto'>
                                {blogData && <PostLike data={blogData} type={"blog"} />}
                            </div>


                            {filteredContent ?
                                <>
                                    {filteredContent.length > 2 &&
                                        <div className='col-lg-8 col-md-10 mx-auto '>
                                            <h6 className='rotate_heading'>SIMILAR BLOGS</h6>
                                            <div className='blog_carousel'>
                                                <OwlCarousel className="owl-theme" {...blogdetailslider} autoplaySpeed={2500}
                                                    autoplayTimeout={3000}

                                                >
                                                    {filteredContent.map((data, i) => (
                                                        <div className="item" onClick={() => updateSlug(data.slug)} key={data.id}>
                                                            <div className='blog_card'>
                                                                <div className='blog_layer'>
                                                                    <img src={data.featureImage} alt="Blog Img" />
                                                                    <div className='blog_overlay'>
                                                                        <small className='blog_time'>{data.timeRead} Minute Read</small>
                                                                        <div className='blog_bottom'>
                                                                            <h6>{data.title}</h6>
                                                                            <small className='blog_date'>{data.date}</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}

                                                </OwlCarousel>
                                            </div>
                                        </div>
                                    }
                                </>
                                : ""}
                        </div>
                        {filteredContent.length <= 2 &&
                            <div className='row'>
                                <div className='col-lg-8 col-md-10 mx-auto'>
                                    <div className='blog_carousel'>
                                        <h6 className='rotate_heading'>SIMILAR BLOGS</h6>
                                        <div className='row'>
                                            {filteredContent.map((data, i) => (
                                                <>
                                                    <div className='col-md-6 mb-md-0 mb-5 mt-md-5 mt-lg-0 mt-5'>
                                                        <div className="item" onClick={() => updateSlug(data.slug)} key={data.id}>
                                                            <div className='blog_card'>
                                                                <div className='blog_layer'>
                                                                    <img src={data.featureImage} alt="Blog Img" />
                                                                    <div className='blog_overlay'>
                                                                        <small className='blog_time'>{data.timeRead} Minute Read</small>
                                                                        <div className='blog_bottom'>
                                                                            <h6>{data.title}</h6>
                                                                            <small className='blog_date'>{data.date}</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div >

                                                </>

                                            ))}

                                        </div>


                                    </div>
                                </div>


                            </div>
                        }
                    </div>

                </div>

                {/* End Blog Detail Portion Code Here  */}
            </>
    )
}

export default BlogDetail
