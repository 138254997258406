import React,{useEffect} from 'react'
import InsightsTabContent from './InsightsTabContent/InsightsTabContent';
// import InsightsHeading from './InsightsHeading/InsightsHeading';

const Insights = () => {
    //    Start Scroll To Top Start Page Code 
    useEffect(() => {
      setTimeout(() => {
        window.scrollTo({ top: 0, left: 0 });
      });
    }, []);
  
    //    End Scroll To Top Start Page Code 
  return (
    <>
     
      {/* Start Insights Tab Content portion Code here  */}
       <InsightsTabContent />
      {/* End Insights Tab Content portion Code here  */}
    </>
  )
}

export default Insights
