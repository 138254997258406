import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "../ServiceSlider/serviceslider.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import serviceimg from "../ServiceSlider/serviceimg.png";
import DOMPurify from 'dompurify';

const ServiceSlider = ({ data, type }) => {

    var arrayLength = data?.length
    // Start Service Slider Owl Carousel Responsive Code 
    const ServiceSlider = {
        loop: true,
        // margin: 0,
        nav: true,
        // smartSpeed: 3000,
        // autoplaySpeed: 6000,
        // autoplaytimeout: 1500,
        // slidetransition: "linear",
        autoplay: true,
        // autoplayTimeout: 6000,
        animateIn: 'fadeIn',
        // animateOut: 'fadeOut',
        // autoplayHoverPause: true,

        responsive: {
            0: {
                items: 1,
                // stagePadding: 10,
                // autoplay: false,
            },
            // 768: {
            //     items: 2,
            //     // stagePadding: 100,
            // },

        },
    };
    // End Service Slider Owl Carousel Responsive Code 
    const navigate = useNavigate();
    return (
        <>
            {/* Start Service Slider Portion Code Here  */}
            {data.length > 0 &&
                <div className='service_slider'>
                    <div className='container'>
                        <div className='row'>
                            {/* <div className='col-lg-5 mb-lg-0 mb-4'>
                            <p>How our expertise helped in the digital transformation of Pimpri-Chinchwad, Maharastra, India</p>
                        </div> */}
                            <div className='col-12 mb-md-0 mb-4'>
                                <OwlCarousel className="owl-theme" {...ServiceSlider} autoplaySpeed={2500}
                                    autoplayTimeout={3000}
                                // slidetransition={linear}
                                >
                                    {data?.map((data, index) => (

                                        <div className='item'>
                                            <div className='slider_card'>
                                                <div className='s_card'>
                                                    <h5 className='slide_counter'>{index + 1}/{arrayLength}</h5>
                                                    <h5>{data.strTitle}</h5>
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: DOMPurify.sanitize(
                                                                data.strDescription
                                                            )
                                                        }}>
                                                    </p>
                                                </div>
                                                <div className='s_card'>
                                                    <div className='service_img'>
                                                        <img src={data.strImage} alt="Service Img" />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </OwlCarousel>
                            </div>
                            <div className='col-12 mt-5 text-md-left text-center'>
                                <Link className='view_case' to={"/casestudies"} state={{ data: type }}  >VIEW CASE STUDY</Link>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default ServiceSlider
