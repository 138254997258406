import React, { useEffect, useState, useRef } from 'react';
import "../TheCompany/thecompany.css";
import "../TheCompany/thecompanyresponsive.css";
import video from "../TheCompany/home.mp4";
import videoposter from "../TheCompany/videoposter.jpg";
import NW1 from "./NW1.jpg";
import NW2 from "./NW2.jpg";
import NW3 from "./NW3.jpg";
import vision from "../TheCompany/vision.jpg";
import creativity from "../TheCompany/creativity.png";
import excellence from "../TheCompany/excellence.png";
import collaboration from "../TheCompany/collaboration.png";
import humane from "../TheCompany/humane.png";
import OurCulture from './OurCulture/OurCulture';
import Counter from './Counter/Counter';
import GetInTouch from './GetInTouch/GetInTouch';
import ClientBox from './ClientBox/ClientBox';
import TimelineCarousel from './TimelineCarousel/TimelineCarousel';
import { useDispatch, useSelector } from 'react-redux';
import { saveDataToAPI } from '../../services/actions/apiActions';
import DOMPurify from 'dompurify';
import axios from 'axios';


// import $ from "jquery";
const TheCompany = () => {
    //    Start Scroll To Top Start Page Code 
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, left: 0 });
        });
    }, []);
    //    End Scroll To Top Start Page Code 

    //start Vision API
    const dispatch = useDispatch();
    const [visionData, setVisionData] = useState(null);

    useEffect(() => {
        async function fetchVisionData() {
            const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/getvisiondata`, { token: process.env.REACT_APP_API_TOKEN })
            if (res.data.status == 200) {
                setVisionData(res.data.content)
            }
        }
        fetchVisionData();
    }, []);

    //end here


    // Start Play & Pause Video Code Here 
    const [play, setPlay] = useState(false);
    const vidRef = useRef(null);
    const playHandle = () => {
        // 👇️ toggle isActive state on click
        setPlay(current => !current);
        vidRef.current.play();
        setIsActive(true);
    };

    const pauseHandle = () => {
        setPlay(false);
        vidRef.current.pause();
        setIsActive(false);
    }
    // ENd Play & Pause Video Code Here 

    // Start Video Button  Mouse Move Hide & Show Portion Code Here 
    const [justHidden, setJustHidden] = useState(false);

    useEffect(() => {
        let timeoutId;

        const handleMouseMove = () => {
            if (!justHidden) {
                setJustHidden(false);
                clearTimeout(timeoutId);
                const buttons = document.querySelectorAll('.video_btn');
                buttons.forEach(div => div.classList.remove('hidden'));
                timeoutId = setTimeout(hide, 2000);
            }
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            clearTimeout(timeoutId);
        };
    }, [justHidden]);


    const hide = () => {
        const buttons = document.querySelectorAll('.video_btn');
        buttons.forEach(div => div.classList.add('hidden'));
    };
    // End Video Button  Mouse Move Hide & Show Portion Code Here 


    //    Start The Company Remove Overlay Code Here 
    const [isActive, setIsActive] = useState(false);
    //    End The Company Remove Overlay Code Here 




    return (
        <>

            {/* Start Video Banner Portion Code Here  */}
            <div className='video_banner'>
                <video
                    // autoPlay={"autoplay"} 
                    // controls={"true"}
                    loop={"loop"}
                    poster={videoposter} ref={vidRef}
                >                        {/* <source src="/rwoods/static/media/Home1.f5f91b86bbfa5eb7e44c.webm" type="video/web" /> */}
                    <source src={video} type="video/mp4" />

                    Your browser does not support the video tag.</video>

                <div className={`overlay ${isActive ? 'overlay-transparent' : ''}`}>
                    <div className='video_btn_container'>
                        <h6>about the company</h6>
                        <div className='video_btn'>
                            <svg xmlns="http://www.w3.org/2000/svg" id='play' viewBox="0 0 96 96" className={play ? 'hide' : ''} onClick={playHandle}>
                                <g id="Group_3050" data-name="Group 3050" transform="translate(-137 -871)">
                                    <g id="Ellipse_83" data-name="Ellipse 83" transform="translate(137 871)" fill="none" stroke="#fff" strokeWidth="1">
                                        <circle cx="48" cy="48" r="48" stroke="none" />
                                        <circle cx="48" cy="48" r="47.625" fill="none" />
                                    </g>
                                    <path id="Polygon_4" data-name="Polygon 4" d="M12.206,1.039a1,1,0,0,1,1.589,0L24.771,15.393A1,1,0,0,1,23.976,17H2.024a1,1,0,0,1-.794-1.607Z" transform="translate(196 906) rotate(90)" fill="#fff" />
                                </g>

                                <title>Play Video</title>

                            </svg>


                            <svg xmlns="http://www.w3.org/2000/svg" id='pause' viewBox="0 0 96 96" className={play ? '' : 'hide'} onClick={pauseHandle}>
                                <g id="Group_3051" data-name="Group 3051" transform="translate(-244 -871)">
                                    <path id="pause_circle_FILL0_wght100_GRAD0_opsz48" d="M158.353-781.472h2.124v-25.87h-2.124Zm12.356,0h2.124v-25.87h-2.124ZM165.593-794.407Z" transform="translate(126.407 1713.407)" fill="#fff" />
                                    <g id="Ellipse_108" data-name="Ellipse 108" transform="translate(244 871)" fill="none" stroke="white" strokeWidth="1">
                                        <circle cx="48" cy="48" r="48" stroke="none" />
                                        <circle cx="48" cy="48" r="47.625" fill="none" />
                                    </g>
                                </g>
                                <title>Pause Video</title>
                            </svg>
                        </div>

                    </div>
                </div>
            </div >



            {/* End Video Banner Portion Code Here  */}


            {/* Start Our Story Portion Code Here  */}
            <div className='our_story'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h2>Our Story</h2>
                        </div>
                        <div className='col-lg-6'>
                            <p className='p_left'>In an ever-changing technological landscape, stagnation is not an option. Being “Nascent” is a mindset - a forward-thinking mindset that allows us to be committed to continuous growth and evolution, both for ourselves and our valued clients.</p>
                        </div>
                        <div className='col-lg-6'>
                            <p className='p_right'>With an unwavering commitment to excellence spanning 16 remarkable years, our journey has been defined by eagerly embracing emerging trends, innovative solutions, and cutting-edge advancements. </p>
                        </div>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-4 col-sm-4'>
                            <img src={NW1} alt="Our Story Img" />
                        </div>
                        <div className='col-md-4 col-sm-4'>
                            <img src={NW2} alt="Our Story Img" />
                        </div>
                        <div className='col-md-4 col-sm-4'>
                            <img src={NW3} alt="Our Story Img" />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 img_caption text-center my-4'>
                            <p>We constantly seek out opportunities to expand our knowledge, skills, and expertise, ensuring that our clients benefit from the latest breakthroughs and industry best practices.</p>
                        </div>
                    </div>

                </div>

            </div>
            {/* End Our Story Portion Code Here  */}


            {/* Start We Have a vision Portion Code Here  */}
            <div className='vision_box'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8 col-md-7'>
                            <p>{visionData?.strTitle}</p>
                            {/* <h6>Advancements in technology hold significant meaning when they are driven by collective efforts to enhance the well-being of people, societies, and the planet. A mindful approach to application and innovation, guided by empathy and good intentions, ensures a more sustainable and equitable future.</h6> */}
                            {/* <h6> Driven by this belief, Nascent has a vision to lead the charge in driving positive change in society through innovative technology solutions. We don’t merely want to be a leader in the technology domain, but to be at the forefront of shaping a brighter, sustainable, and equitable future for all.</h6> */}
                            {/* <h5>Why do we Exist? <span>Lorem ipsum dolor sit amet, consetetur sadipscing nonumy eirmod tempor invi</span> Mission & Vision <span>dunt labore et dolore aliquyam erat, sed diam voluptua.</span></h5> */}
                            <span dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    visionData?.strDescription
                                ),
                            }}>
                            </span>
                        </div>
                        <div className='col-lg-4 col-md-5'>
                            <div className='vision_img'>
                                <img src={visionData?.strImage} alt="Vision Img" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* End We Have a vision Portion Code Here  */}


            {/* Start Values we thirive on Portion Code Here  */}
            <div className='values_box'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h5>Values we thrive on</h5>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-3 col-md-3 col-sm-6 col-6'>
                            <div className='v_icon'>
                                <div className='v_icon_img'>
                                    <img src={humane} alt="Humane" />
                                </div>
                                <p>Humane</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-3 col-sm-6 col-6'>
                            <div className='v_icon'>
                                <div className='v_icon_img'>
                                    <img src={excellence} alt="Excellence" />
                                </div>
                                <p>Excellence</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-3 col-sm-6 col-6'>
                            <div className='v_icon'>
                                <div className='v_icon_img'>
                                    <img src={creativity} alt="Creativity" />
                                </div>
                                <p>Creativity</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-3 col-sm-6 col-6'>
                            <div className='v_icon'>
                                <div className='v_icon_img'>
                                    <img src={collaboration} alt="Collaboration" />
                                </div>
                                <p>Collaboration</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Values we thirive on Portion Code Here  */}


            {/* Start Timeline Carousel Portion Code Here */}
            <TimelineCarousel />
            {/* End Timeline Carousel Portion Code Here */}


            {/* Start Our Culture Portion Code Here */}
            <OurCulture />
            {/* End Our Culture Portion Code Here */}

            {/* Start Who we’ve worked with Portion Code Here  */}
            <ClientBox />
            {/* End Who we’ve worked with Portion Code Here  */}

            {/* Start Client Counter portion Code Here  */}
            <Counter />
            {/* End Client Counter portion Code Here  */}



            {/* Start Get In Touch Portion Code Here  */}
            <GetInTouch />
            {/* End Get In Touch Portion Code Here  */}


        </>
    )
}

export default TheCompany
