import React, { useRef, useState } from 'react';
import "../ourproducts/ourproducts.css";
import "../ourproducts/responsiveproduct.css";
import product from "../ourproducts/product.jpg";
import product2 from "../ourproducts/product2.jpg";
import product3 from "../ourproducts/product3.jpg";
import product4 from "../ourproducts/product4.jpg";
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Button, Card, Modal, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import swal from 'sweetalert';
import axios from 'axios';
import Loader from '../../Loader/Loader';

const Products = () => {
    // Start Products Form Portion Code Here 
    const [show, setShow] = useState(false);


    const applyFormShow = () => setShow(true);


    // End Products Form Portion Code Here 

    const phoneRef = useRef();
    const { register, handleSubmit, control, reset, formState: { errors }, setValue } = useForm();
    const [loader, setLoader] = useState(false);

    const closeModal = () => {
        setShow(false);
        reset();
    }
    const onSubmit = async (data) => {
        try {
            setLoader(true);

            const apiData = {
                ...data, token: process.env.REACT_APP_API_TOKEN
            }
            const response = await axios.post(`${process.env.REACT_APP_API_SERVER}/productdemo`, apiData, { headers: { "Content-Type": "multipart/form-data" } })
            if (response.data.status == 200) {
                reset();
                setShow(false);
                swal(
                    `Thank You for showing interest in demo of ${data.product} Product .`,
                    "Nascent Team will connect you soon",
                    "success"
                );
                setLoader(false);

            }
        } catch (error) {
            swal(
                "Server Error",
                error.message,
                "error"
            );
            setLoader(false);
        }

    }

    return (
        <>
            {/* Start Our Products Portion Code Here  */}
            <div className='our_products'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <h2>Our Products</h2>
                            <p>Nascent’s guiding principle is rooted in its passion for innovation. We champion ideas, technologies and initiatives that foster positive change and advancement, aligning with our commitment towards a more progressive and forward-thinking approach.</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-8 col-md-8'>
                            <div id="outerAccordion" className='accordion'>
                                <div className="card">
                                    <div className="card-header" id="section1">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link" data-toggle="collapse" data-target="#innerAccordion1" aria-expanded="true" aria-controls="innerAccordion1">
                                                <span>Layers</span>
                                                <span className='plus'>
                                                    <svg id="Group_259" data-name="Group 259" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                                        <path id="Path_214" data-name="Path 214" d="M-4488-7118.6h24" transform="translate(4488 7130.595)" fill="none" stroke="#fff" strokeWidth="1.5" />
                                                        <path id="Path_215" data-name="Path 215" d="M0,0H24" transform="translate(12) rotate(90)" fill="none" stroke="#fff" strokeWidth="1.5" />
                                                    </svg>
                                                </span>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="innerAccordion1" className="collapse show" aria-labelledby="section1" data-parent="#outerAccordion">
                                        <div className="card-body">
                                            <h6 className='text-white sub_head_a'>Open Source Web-GIS Platform</h6>
                                            <div className='product_card'>
                                                {/* <div className='p_img'>
                                                                    <img src={product} alt="Product Image" />
                                                                </div> */}
                                                <div className='p_text'>
                                                    <p>A powerful web-based GIS platform designed to transform the way you interact with geospatial data. Publish, visualise, search, discover, edit, and manage maps and geospatial information on an OGC compliant solution built on open source technologies. LAYERS unleashes the full potential of location-based insights, empowering you to make data-driven decisions with precision and confidence.
                                                    </p>

                                                </div>
                                                <div className='p_arrow'>
                                                    <Link to="https://www.nascentinfo.com/citylayers/index.html" target='_blank'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="15.188" viewBox="0 0 53.553 15.188">
                                                            <g id="Group_262" data-name="Group 262" transform="translate(-1619.174 -1556.049)">
                                                                <path id="Path_73" data-name="Path 73" d="M-3576.721-9233.079h52.493" transform="translate(5195.895 10796.722)" fill="none" stroke="#fff" strokeWidth="1.5" />
                                                                <path id="Path_74" data-name="Path 74" d="M-3495.606-9264.768l7.063,7.063-7.063,7.063" transform="translate(5160.209 10821.347)" fill="none" stroke="#fff" strokeWidth="1.5" />
                                                            </g>
                                                        </svg>
                                                    </Link>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <div className="card">
                                    <div className="card-header" id="section2">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#innerAccordion2" aria-expanded="true" aria-controls="innerAccordion2">
                                                <span>N-Collector</span>
                                                <span className='plus'>
                                                    <svg id="Group_259" data-name="Group 259" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                                        <path id="Path_214" data-name="Path 214" d="M-4488-7118.6h24" transform="translate(4488 7130.595)" fill="none" stroke="#fff" strokeWidth="1.5" />
                                                        <path id="Path_215" data-name="Path 215" d="M0,0H24" transform="translate(12) rotate(90)" fill="none" stroke="#fff" strokeWidth="1.5" />
                                                    </svg>
                                                </span>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="innerAccordion2" className="collapse " aria-labelledby="section2" data-parent="#outerAccordion">
                                        <div className="card-body">
                                            <h6 className='text-white sub_head_a'></h6>
                                            <div className='product_card'>
                                              
                                                <div className='p_text'>
                                                    <p>A powerful customisable mobile application designed for data collection, utilising GIS technology. The app allows you to create personalised survey forms to conduct surveys, and inspections, and gather various types of data, including multimedia files.

                                                    </p>

                                                </div>
                                                <div className='p_arrow'>
                                                    <Link >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="15.188" viewBox="0 0 53.553 15.188">
                                                            <g id="Group_262" data-name="Group 262" transform="translate(-1619.174 -1556.049)">
                                                                <path id="Path_73" data-name="Path 73" d="M-3576.721-9233.079h52.493" transform="translate(5195.895 10796.722)" fill="none" stroke="#fff" strokeWidth="1.5" />
                                                                <path id="Path_74" data-name="Path 74" d="M-3495.606-9264.768l7.063,7.063-7.063,7.063" transform="translate(5160.209 10821.347)" fill="none" stroke="#fff" strokeWidth="1.5" />
                                                            </g>
                                                        </svg>
                                                    </Link>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div> */}

                            </div>

                        </div>

                        <div className='col-lg-4 col-md-4 mt-auto'>
                            <div className='p_btn' onClick={applyFormShow}>
                                <Button className='mt-auto request_btn'>REQUEST A DEMO</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Our Products Portion Code Here  */}
            {/* Start Apply Form Portion Code Here  */}
            <Modal show={show} onHide={closeModal} centered>
                <Modal.Header>
                    <Modal.Title>Product Demo</Modal.Title>
                    <Button aria-hidden="true" onClick={closeModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 24 24">
                            <g id="Group_3024" data-name="Group 3024" transform="translate(-1727 -51)">
                                <g id="Group_3023" data-name="Group 3023" transform="translate(-0.097 -0.139)">
                                    <path id="Path_21074" data-name="Path 21074" d="M-3824.9,5085.139l15.754,15.754" transform="translate(5556 -5030)" fill="none" stroke="#fff" strokeWidth="2" />
                                    <path id="Path_21075" data-name="Path 21075" d="M-3824.9,5085.139l15.754,15.754" transform="translate(6831.99 3880.042) rotate(90)" fill="none" stroke="#fff" strokeWidth="2" />
                                </g>
                                <rect id="Rectangle_402" data-name="Rectangle 402" width="24" height="24" transform="translate(1727 51)" fill="none" />
                            </g>
                        </svg>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Form className='collaborate_form' onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='col-lg-12 form-group'>

                                <Form.Control as="select" name='product'
                                    {...register("product", {
                                        required: "Please fill out this field.",


                                    })}>
                                    <option selected value={""}>Select Product</option>
                                    <option value={"Layers"}>Layers</option>
                                    {/* <option value={"N-Collector"}>N-Collector</option> */}
                                </Form.Control>
                                {errors.product && <label className="error">
                                    {errors.product.message}
                                </label>}
                            </div>
                            <div className='col-lg-12 form-group'>
                                <Form.Label>Full Name<span>*</span></Form.Label>
                                <Form.Control type='text'
                                    name='fullName'
                                    {...register("fullName", {
                                        required: "Please fill out this field.",
                                        pattern: {
                                            value: /^[a-zA-Z ]*$/,
                                            message: "Only alphabetical is allowed in name field."
                                        },
                                        minLength:
                                        {
                                            value: '3',
                                            message: 'Minimum 3 characters are allowed.'
                                        },
                                        maxLength:
                                        {
                                            value: '50',
                                            message: 'Maximum 50 characters are allowed.'
                                        }
                                    })}
                                />
                                {errors.fullName && <label className="error">
                                    {errors.fullName.message}
                                </label>}
                            </div>
                            <div className='col-lg-12  form-group'>
                                <Form.Label>Email<span>*</span></Form.Label>
                                <Form.Control type='text' name='email'
                                    {...register("email", {
                                        required: "Please fill out this field.",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Please enter a valid email address."
                                        },
                                        maxLength:
                                        {
                                            value: '50',
                                            message: 'Maximum 50 characters are allowed.'
                                        }
                                    })}
                                />
                                {errors.email && <label className="error">
                                    {errors.email.message}
                                </label>}
                            </div>
                            <div className='col-lg-12  form-group'>
                                <Form.Label>Name of the Organisation<span>*</span></Form.Label>
                                <Form.Control type="text"
                                    name='company'
                                    {...register("company", {
                                        required: "Please fill out this field.",
                                        minLength:
                                        {
                                            value: '3',
                                            message: 'Minimum 3 characters are allowed.'
                                        },
                                        maxLength:
                                        {
                                            value: '50',
                                            message: 'Maximum 50 characters are allowed.'
                                        }
                                    })}
                                />
                                {errors.company && <label className="error">
                                    {errors.company.message}
                                </label>}
                            </div>
                            <div className='col-lg-12  form-group'>
                                <Form.Label>Contact Number<span>*</span></Form.Label>
                                <Controller
                                    name="phone"
                                    control={control}

                                    rules={{
                                        required: "Please fill out this field.",
                                        minLength:
                                        {
                                            value: '15',
                                            message: 'Please enter a valid phone number.'
                                        },
                                    }}
                                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                        <PhoneInput
                                            enableSearch
                                            onBlur={onBlur}
                                            country={'in'}

                                            ref={phoneRef}

                                            countryCodeEditable={false}
                                            onChange={(value, data, event, formattedValue) => {
                                                setValue("phone", formattedValue, {
                                                    shouldValidate: true,
                                                    shouldDirty: true
                                                });
                                            }}

                                        />
                                    )}
                                />
                                {errors.phone && <label className="error">
                                    {errors.phone.message}
                                </label>}

                            </div>


                            <div className='col-12'>
                                <div className='form-group'>
                                    <Form.Label>Please describe your specific requirement<span>*</span></Form.Label>
                                    <textarea placeholder='Message'
                                        name='message'
                                        {...register("message", {
                                            required: "Please fill out this field.",
                                            minLength:
                                            {
                                                value: '5',
                                                message: 'Minimum 5 characters are allowed.'
                                            },
                                            maxLength:
                                            {
                                                value: '500',
                                                message: 'Maximum 500 characters are allowed.'
                                            }
                                        }
                                        )}
                                        rows={3}>

                                    </textarea>
                                    {errors.message && <label className="error">
                                        {errors.message.message}
                                    </label>}
                                </div>

                            </div>

                        </div>
                        <Button className='apply_btn' type='submiy' >
                            BOOK NOW
                        </Button>
                    </Form>


                </Modal.Body>

            </Modal>
            {/* End Apply Form Portion Code Here  */}
            {loader && <Loader />}
        </>
    )
}

export default Products
